import React from 'react'
import { Route, Switch } from 'react-router-dom'

import routes from 'routes'
import { DeviceProvider } from 'modules/core/containers'

export default class App extends React.Component {
    render() {
        return (
            <DeviceProvider>
                <Switch>
                    {routes.map((route, i) => (
                        <Route key={i} {...route} />
                    ))}
                </Switch>
            </DeviceProvider>
        )
    }
}
