export const buildUnabbreviator = (mapping) => {
    return (abbrForm, { showAbbr = false } = {}) => {
        const longForm = mapping[abbrForm]
        if (longForm === undefined) {
            return abbrForm
        } else if (showAbbr) {
            return `${longForm} (${abbrForm})`
        } else {
            return longForm
        }
    }
}

const valueRepresentationAbbrs = {
    AE: 'Application Entity',
    AS: 'Age String',
    AT: 'Attribute Tag',
    CS: 'Code String',
    DA: 'Date',
    DS: 'Decimal String',
    DT: 'Date Time',
    FL: 'Single',
    FD: 'Double',
    IS: 'Integer String',
    LO: 'Long String',
    LT: 'Long Text',
    OB: 'Other Byte String',
    OD: 'Other Double String',
    OF: 'Other Float String',
    OW: 'Other Word String',
    PN: 'Person Name',
    SH: 'Short String',
    SL: 'Signed Long',
    SQ: 'Sequence',
    SS: 'Signed Short',
    ST: 'Short Text',
    TM: 'Time',
    UC: 'Unlimited Characters',
    UI: 'Unique Identifier',
    UL: 'Unsigned Long',
    UR: 'URL',
    UN: 'Unknown',
    US: 'Unsigned Short',
    UT: 'Unlimited Text',
}

export const unabbrValueRepresentation = buildUnabbreviator(
    valueRepresentationAbbrs
)

const moduleUsageAbbrs = {
    M: 'Mandatory',
    C: 'Conditional',
    U: 'User Optional',
}

export const unabbrModuleUsage = buildUnabbreviator(moduleUsageAbbrs)

const attributeTypes = {
    1: 'Required',
    '1C': 'Conditionally Required',
    2: 'Required, Empty if Unknown',
    '2C': 'Conditionally Required, Empty if Unknown',
    3: 'Optional',
}

export const unabbrAttributeType = buildUnabbreviator(attributeTypes)
