import React from 'react'
import { Redirect } from 'react-router-dom'

import standard from 'utils/dicomStandard'
import { DicomBrowser } from 'modules/core/containers'
import { Terms, MissingPage } from 'modules/core/components'
import { loadExternalReference } from 'modules/details/actions'
import { openSidePane } from 'modules/panes/actions'
import { openNode } from 'modules/tree/actions'
import { queueExample } from 'modules/analyze/actions'
import { getFocusedNode } from 'modules/tree/selectors'
import { getNodeParent } from 'modules/tree/utils'
import { InvalidDicomNode } from 'utils/dicom'
import { getNodePath } from 'utils/urlActions'

export default [
    {
        exact: true,
        path: '/',
        render: (props) => <Redirect {...props} to="/ciods" />,
        async loadData(match, location, context, store, queryParams) {
            if (queryParams.example) {
                store.dispatch(queueExample(queryParams.example));
                store.dispatch(openSidePane('analyze'))
            }
        },
    },
    {
        exact: true,
        path: '/terms',
        component: Terms,
    },
    {
        path: '/ciods',
        component: (props) => <DicomBrowser {...props} />,
        async loadData(match, location, context, store, queryParams) {
            const focusedNode = getFocusedNode(location)
            try {
                standard.validateNodePath(focusedNode)
                store.dispatch(openNode(focusedNode))
                if (focusedNode.length > 0)
                    store.dispatch(openSidePane('details'))
                await loadMissingReferences(focusedNode, store)
            } catch (error) {
                if (error instanceof InvalidDicomNode) {
                    context.url = getNodePath([]).pathname
                }
            }
            if (queryParams.example) {
                store.dispatch(queueExample(queryParams.example));
                store.dispatch(openSidePane('analyze'))
            }
        },
    },
    {
        component: MissingPage,
    },
]

async function loadMissingReferences(focusedNode, store) {
    const { externalReferences } = standard.nodeDetails(focusedNode)
    const promises = externalReferences.map((ref) =>
        store.dispatch(loadExternalReference(ref.sourceUrl))
    )
    await Promise.all(promises)
}
