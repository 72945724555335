/*
 * API for accessing public DICOM example files hosted on the Innolitics S3
 * bucket.
 */
import dcmjs from 'dcmjs'
import { DOMParser, Document } from 'xmldom'

import { Dataset } from './dataset'

const PUBLIC_EXAMPLES_S3_BUCKET = 'https://public-dicom-examples.s3.amazonaws.com'

const getXmlResponse = async (url: string): Promise<Document> => {
    const responseText = await (await fetch(url)).text()
    return new DOMParser().parseFromString(responseText, 'text/xml')
}

// Parse the XML response from the S3 bucket and return the list of available
// example DICOM file names.
export const getAvailableExamples = async (): Promise<string[]> => {
    const bucketListingXML = await getXmlResponse(PUBLIC_EXAMPLES_S3_BUCKET)
    const fileNames = bucketListingXML.getElementsByTagName('Key')
    const exampleFiles = []
    for (let i = 0; i < fileNames.length; i++) {
        exampleFiles.push(fileNames[i].lastChild.nodeValue)
    }
    return exampleFiles
}

export const downloadExampleDICOM = async (fileName: string): Promise<Dataset> => {
    const buffer = await (
        await fetch(PUBLIC_EXAMPLES_S3_BUCKET + '/' + fileName)
    ).arrayBuffer()

    const dicomDict = dcmjs.data.DicomMessage.readFile(buffer)
    return new Dataset(
        dicomDict,
        fileName,
    )
}
