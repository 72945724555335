import './polyfills'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import Root from 'Root'
import { history } from 'config'
import configureStore from 'store'
import sendGA, { initializeGa } from 'external/googleAnalytics'

import 'stylesheets/normalize.scss'
import 'stylesheets/utils.scss'
import 'stylesheets/global.scss'
import 'stylesheets/responsive.scss'

if (PRODUCTION) {
    initializeGa()

    history.listen((location) => {
        sendGA('send', 'pageview', location.pathname)
    })
}

const store = configureStore(window.__INITIAL_STATE__)

const render = PRODUCTION ? ReactDOM.hydrate : ReactDOM.render

render(
    <Provider store={store}>
        <Root />
    </Provider>,
    document.getElementById('root')
)
