import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { batchActions } from 'redux-batched-actions'
import sendGA, { sendGAConversion } from 'external/googleAnalytics'

import standard from 'utils/dicomStandard'
import {
    Meta,
    SearchEngineBreadcrumbs,
    Banner,
    SiteFooter,
} from '../components'
import { withDevice } from '../hocs'
import { nodeMetaDescription } from '../utils'
import { DetailPane } from 'modules/details/components'
import { MessageList } from 'modules/messages/containers'
import { addMessage } from 'modules/messages/actions'
import { SidePane } from 'modules/panes/containers'
import { Tab } from 'modules/panes/components'
import { openSidePane } from 'modules/panes/actions'
import { SearchPane } from 'modules/search/containers'
import { AnalyzePane } from 'modules/analyze/containers'
import { TreeTable } from 'modules/tree/containers'
import { NodeLink, NodePath } from 'modules/tree/components'
import { openNode, selectNode, setScroll } from 'modules/tree/actions'
import { getFocusedNode } from 'modules/tree/selectors'
import { getNodeParent } from 'modules/tree/utils'
import { InvalidDicomNode } from 'utils/dicom'

import 'stylesheets/panes.scss'

const mapStateToProps = (state, props) => {
    const {
        tree: { scroll },
    } = state
    const {
        location: { pathname },
    } = props
    const focusedNode = getFocusedNode(pathname)
    const {
        analyze: { dataset },
    } = state
    return { focusedNode, scroll, dataset }
}

const mapDispatchToProps = {
    batchActions,
    openNode,
    openSidePane,
    selectNode,
    setScroll,
}

@withDevice
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class DicomBrowser extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const { setScroll } = this.props

        if (!PRODUCTION) setScroll(true)
    }

    componentDidUpdate() {
        const { focusedNode, scroll, setScroll } = this.props

        try {
            standard.validateNodePath(focusedNode)
        } catch (error) {
            if (error instanceof InvalidDicomNode) {
                addMessage('warning', error.message)
                selectNode([])
            }
        }

        if (scroll) {
            setScroll(false)
            // https://stackoverflow.com/a/34999925
            setTimeout(() => window.requestAnimationFrame(scrollToFocusedNode))
        }
    }

    handleNodeClick = (node, index) => {
        const { batchActions } = this.props

        return (event) => {
            event.preventDefault()
            sendGA('send', {
                hitType: 'event',
                eventCategory: 'search',
                eventAction: 'click',
                eventValue: index,
            })
            batchActions([
                selectNode(node),
                openNode(getNodeParent(node)),
                setScroll(true),
                openSidePane('details'),
            ])
        }
    }

    handleEntryClick = (node, nodeToOpen) => {
        const { batchActions } = this.props
        return (event, first = false) => {
            if (!first) {
                event.preventDefault()
                batchActions([
                    selectNode(node),
                    openNode(nodeToOpen),
                    setScroll(true),
                    openSidePane('details'),
                ])
            } else {
                batchActions([
                    selectNode(node),
                    openNode(nodeToOpen),
                    setScroll(true),
                ])
            }
        }
    }

    nodeSearchResult = ({ _source, _id }, index) => {
        const handleNodeClick = this.handleNodeClick(_source.node, index)
        return (
            <li key={_id}>
                <NodeLink
                    node={_source.node}
                    standard={standard}
                    onNodeClick={handleNodeClick}
                    includeNodeType
                    includeTag
                />
                <br />
                <span className="text-small text-very-muted">
                    <NodePath
                        node={getNodeParent(_source.node)}
                        standard={standard}
                        onNodeClick={handleNodeClick}
                    />
                </span>
            </li>
        )
    }

    openFilePath = (path, openPath) => {
        return this.handleEntryClick(path, openPath)
    }

    renderBanner = () => {
        return <Banner bannerId="inno-banner" content={
            <>
                <a
                    id="logo"
                    href="https://innolitics.com"
                    onClick={() => sendGAConversion('AW-878060731/AiNeCO3O_bwYELvJ2KID')}
                >
                    <img src="/public/logo.svg"></img>
                </a>
                <span className="banner-text">
                Need software developers with DICOM expertise? We can help!{" "}
                    <a
                        href="https://innolitics.com/services/end-to-end-samd/"
                        onClick={() => sendGAConversion('AW-878060731/AiNeCO3O_bwYELvJ2KID')}
                    >
                        Click here to learn more
                    </a>.
                </span>
            </>
        }/>
    }

    render() {
        const {
            device,
            focusedNode,
            openSidePane,
            selectNode,
            setScroll,
            dataset,
        } = this.props
        const focusedNodeDetails = standard.nodeDetails(focusedNode)

        return (
            <div>
                <div className="global-pane-container">
                    {this.renderBanner()}
                    <div className="pane-container">
                        <Meta
                            title={focusedNodeDetails.fullName}
                            description={nodeMetaDescription(
                                focusedNodeDetails
                            )}
                            canonicalUrl={focusedNodeDetails.canonicalUrl}
                        />
                        <SearchEngineBreadcrumbs
                            focusedNode={focusedNode}
                            standard={standard}
                        />
                        <div className="pane-primary">
                            <MessageList />
                            <div className="pane-header">
                                <h1 className="text-primary">
                                    <Link to="/ciods">
                                        DICOM{' '}
                                        <span className="hidden-sm-down">
                                            Standard
                                        </span>{' '}
                                        Browser
                                    </Link>
                                </h1>
                            </div>
                            <div className="pane-content">
                                <TreeTable
                                    focusedNode={focusedNode}
                                    standard={standard}
                                    onNodeClick={() => openSidePane('details')}
                                    selectNode={selectNode}
                                    missingNodes={
                                        dataset ? dataset.missing : []
                                    }
                                    ciodId={dataset ? dataset.ciodId : null}
                                    givenElements={
                                        dataset ? dataset.uniqueElements : null
                                    }
                                />
                                <div className="m-x-1">
                                    <SiteFooter />
                                </div>
                            </div>
                        </div>
                        <SidePane key={device}>
                            <Tab name="details" label="Details">
                                <DetailPane
                                    standard={standard}
                                    focusedNode={focusedNode}
                                    dataset={dataset}
                                />
                            </Tab>
                            <Tab name="search" label="Search">
                                <SearchPane
                                    displayResult={this.nodeSearchResult.bind(
                                        this
                                    )}
                                />
                            </Tab>
                            <Tab
                                name="analyze"
                                label="File Editor"
                            >
                                <AnalyzePane
                                    openFilePath={this.openFilePath.bind(this)}
                                    focusedNode={focusedNode}
                                />
                            </Tab>
                        </SidePane>
                    </div>
                    {/* <SurveyWidgetComponent /> */}
                </div>
            </div>
        )
    }
}
