import { SEARCH_BEGIN, SEARCH_FINISH, SEARCH_ERROR } from './actions'

const initialState = {
    pending: false,
    results: [],
    numResults: 0,
    page: 1,
    size: 15,
    error: null,
    query: '',
    token: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_BEGIN:
            return {
                ...state,
                pending: true,
                results: [],
                size: action.size,
                page: action.page,
                query: action.query,
                token: action.token,
                error: null,
            }
        case SEARCH_FINISH:
            return {
                ...state,
                pending: false,
                results: action.results ? action.results.hits.hits : [],
                numResults: action.results ? action.results.hits.total : 0,
                error: null,
            }
        case SEARCH_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        default:
            return state
    }
}
