import React from 'react'
import _ from 'lodash'
import { Router } from 'react-router-dom'
import { hot } from 'react-hot-loader'
import { connect } from 'react-redux'

import App from 'App'
import { ErrorBoundary } from 'modules/core/components'
import { history } from 'config'
import { downloadExample } from 'modules/analyze/actions'

const mapStateToProps = (state, _ownProps) => {
    return {
        queuedExample: state.analyze.queuedExample
    }
}

const mapDispatchToProps = {
    downloadExample,
}

@hot(module)
@connect(mapStateToProps, mapDispatchToProps)
export default class Root extends React.Component {
    componentDidMount() {
        if (!_.isUndefined(this.props.queuedExample)) {
            this.props.downloadExample(this.props.queuedExample);
        }
    }

    render() {
        return (
            <Router history={history}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </Router>
        )
    }
}
