import React from 'react'
import classNames from 'classnames'

import { moduleDetails, attributeDetails } from '../utils'

import './NodeDetailsTable.scss'
import ExamplesCell from './ExamplesCell'

const valueRepresentationHref =
    'http://dicom.nema.org/medical/dicom/current/output/chtml/part05/sect_6.2.html'

export default class NodeDetailsTable extends React.Component {
    render() {
        const { node, fileValue, isMissing, isEmpty } = this.props

        let details
        switch (node.nodeType) {
            case 'module':
                details = moduleDetails(node)
                break
            case 'attribute':
                details = attributeDetails(node, fileValue)
                break
            default:
                return null
        }

        return (
            <table className="node-details-table">
                <tbody>
                    {details.map(({ header, value }) => {
                        const name = classNames('value', {
                            'missing-value': isMissing && value === 'Missing',
                            'empty-value':
                                isEmpty &&
                                (value === 'Empty' || value === 'binary data'),
                        })
                        return (
                            <tr key={header}>
                                <th>
                                    {header === 'Value Representation' ? (
                                        <a href={valueRepresentationHref}>
                                            Value Representation
                                        </a>
                                    ) : (
                                        header
                                    )}
                                </th>
                                {value && value.length > 35 ? (
                                    <td className={name} title={value}>
                                        {header === 'Example Values' ? (
                                            <ExamplesCell examples={value} />
                                        ) : (
                                            value
                                        )}
                                    </td>
                                ) : (
                                    <td className={name}>
                                        {header === 'Example Values' ? (
                                            <ExamplesCell examples={value} />
                                        ) : (
                                            value
                                        )}
                                    </td>
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }
}
