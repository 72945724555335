import { head, range, take, without } from 'lodash'

export const parentsPaths = (nodePath) => {
    const numParents = nodePath.length
    return range(numParents).map((i) => {
        return take(nodePath, i)
    })
}

export const nodeMetaDescription = (node) => {
    if (node.nodeType === 'top') {
        return '<p>An interactive and searchable representation of part 3 of the DICOM standard.</p>'
    } else {
        return extractFirstParagraph(node.description)
    }
}

export const extractFirstParagraph = (html) => {
    const paragraphSeparated = html.split(/<p>|<\/p>/)
    const firstParagraph = head(without(paragraphSeparated, ''))
    return firstParagraph.replace(/<(.*?)>/g, '')
}
