import React from 'react'

export default class ErrorPageContent extends React.Component {
    render() {
        return (
            <React.Fragment>
                <p>We apologize about any inconvenience.</p>
                <p>
                    Though we try hard to make our site work smoothly, sometimes
                    bugs slip by! We value your security, so we don’t use any error
                    reporting software to avoid collecting PHI. Please report this
                    error{' '}
                    <a href="https://github.com/innolitics/dicom-standard/issues/new/choose">
                        here{' '}
                    </a>
                    and include any relevant (de-identified) DICOM files. Your
                    feedback helps us improve this tool for our growing community of
                    users, thank you.
                </p>
            </React.Fragment>
        )
    }
}
