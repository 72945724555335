import Standard from './dicom'

import ciods from '../../dicom/standard/ciods.json'
import ciodToModule from '../../dicom/standard/ciod_to_modules.json'
import modules from '../../dicom/standard/modules.json'
import moduleToAttribute from '../../dicom/standard/module_to_attributes.json'
import attributes from '../../dicom/standard/attributes.json'
import sops from '../../dicom/standard/sops.json'

export default new Standard({
    ciods,
    ciodToModule,
    modules,
    moduleToAttribute,
    attributes,
    sops,
})
