import {
    REQUEST_REFERENCE,
    RECEIVE_REFERENCE,
    REQUEST_REFERENCE_FAILED,
} from './actions'

export default (state = {}, action) => {
    let newState
    switch (action.type) {
        case RECEIVE_REFERENCE:
            newState = Object.assign({}, state)
            if (referenceNotLoaded(newState, action.url)) {
                newState[action.url] = {
                    content: action.content,
                    url: action.url,
                    state: 'loaded',
                }
            }
            return newState
        case REQUEST_REFERENCE:
            newState = Object.assign({}, state)
            if (referenceNotLoaded(newState, action.url)) {
                newState[action.url] = {
                    content: null,
                    url: action.url,
                    state: 'loading',
                }
            }
            return newState
        case REQUEST_REFERENCE_FAILED:
            newState = Object.assign({}, state)
            if (referenceNotLoaded(newState, action.url)) {
                newState[action.url] = {
                    content: null,
                    url: action.url,
                    state: 'failed',
                }
            }
            return newState
        default:
            return state
    }
}

const referenceNotLoaded = (state, referenceUrl) => {
    return (
        state[referenceUrl] === undefined ||
        state[referenceUrl]['state'] !== 'loaded'
    )
}
