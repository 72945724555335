import React from 'react'

import { ErrorPage } from '.'

export default class ErrorBoundary extends React.Component {
    state = { hasError: false }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    render() {
        const { hasError } = this.state

        return hasError ? <ErrorPage /> : this.props.children
    }
}
