import { memoize, map, filter, last, keys, find, extend } from 'lodash'

export const baseSiteUrl = 'https://dicom.innolitics.com/ciods'

export function InvalidDicomNode(nodePath) {
    this.name = 'InvalidDicomNode'
    this.message = `Unknown DICOM Node "${nodePath.join(
        '/'
    )}".  Perhaps there is a typo in the URL?`
    this.stack = new Error().stack
}
InvalidDicomNode.prototype = Object.create(Error.prototype)
InvalidDicomNode.prototype.constructor = InvalidDicomNode

/**
 * Class representing a copy of the DICOM standard.
 *
 * NOTE: May only be partially loaded (and hence some of the data tables would
 * be undefined)
 */
export default class Standard {
    constructor({
        ciods,
        ciodToModule,
        modules,
        moduleToAttribute,
        attributes,
        sops,
    }) {
        this.ciods = ciods
        this.ciodToModule = ciodToModule
        this.modules = modules
        this.moduleToAttribute = this._filterByReachableModules(
            moduleToAttribute,
            ciodToModule
        )
        this.attributes = attributes
        this.sops = sops

        this.canonicalModuleLinks = this._makeCanonicalModuleList(
            this.ciodToModule
        )
        this.canonicalAttributeLinks = this._makeCanonicalAttributeList(
            this.moduleToAttribute,
            this.canonicalModuleLinks
        )
        this.nodeChildren = memoize(this._nodeChildren)
        this.nodeDetails = memoize(this._nodeDetails)
    }

    nodeType(nodePath) {
        const pathLength = nodePath.length
        if (pathLength === 0) {
            return 'top'
        } else if (pathLength === 1) {
            return 'ciod'
        } else if (pathLength === 2) {
            return 'module'
        } else {
            return 'attribute'
        }
    }

    _filterByReachableModules(moduleToAttribute, ciodToModule) {
        let reachableModules = new Set(map(ciodToModule, 'moduleId'))
        return filter(moduleToAttribute, (rel) =>
            reachableModules.has(rel.moduleId)
        )
    }

    _makeCanonicalModuleList(ciodToModule) {
        let canonicalModuleLinks = {}
        let visitedElements = new Set([])
        ciodToModule.forEach((rel) => {
            if (!visitedElements.has(rel.moduleId)) {
                canonicalModuleLinks[rel.moduleId] =
                    baseSiteUrl + '/' + rel.ciodId + '/' + rel.moduleId
                visitedElements.add(rel.moduleId)
            }
        })
        return canonicalModuleLinks
    }

    _makeCanonicalAttributeList(moduleToAttribute, canonicalModuleLinks) {
        let canonicalAttributeLinks = {}
        let visitedElements = new Set([])
        moduleToAttribute.forEach((rel) => {
            if (!visitedElements.has(rel.tag)) {
                const splitPath = rel.path.split(':')
                const attributePath = splitPath.slice(1, splitPath.length)
                canonicalAttributeLinks[rel.tag] =
                    canonicalModuleLinks[rel.moduleId] +
                    '/' +
                    attributePath.join('/')
                visitedElements.add(rel.tag)
            }
        })
        return canonicalAttributeLinks
    }

    /**
     * Determine if a node has children.
     */
    hasChildren(nodePath) {
        const type = this.nodeType(nodePath)
        if (type === 'attribute') {
            const tag = last(nodePath)
            const valueRepresentation = this.attributes[tag].valueRepresentation
            return valueRepresentation === 'SQ'
        } else {
            return true
        }
    }

    validateNodePath(nodePath) {
        // TODO: split this into a separate function that doesn't use
        // `_nodeDetails` internally, and use it at the start of
        // `_nodeDetails` and remove validation from `_nodeDetails`
        // (use git blame to see this commit)
        this._nodeDetails(nodePath)
    }

    _nodeChildren(nodePath) {
        this.validateNodePath(nodePath)
        let children
        const type = this.nodeType(nodePath)
        if (type === 'top') {
            children = this._topNodeChildren()
        } else if (type === 'ciod') {
            children = this._ciodNodeChildren(nodePath)
        } else if (type === 'module') {
            children = this._moduleNodeChildren(nodePath)
        } else {
            children = this._attributeNodeChildren(nodePath)
        }
        return children
    }

    _nodeDetails(nodePath) {
        let details
        const nodeType = this.nodeType(nodePath)
        if (nodeType === 'top') {
            details = this._topNodeDetails()
        } else if (nodeType === 'ciod') {
            details = this._ciodNodeDetails(nodePath)
        } else if (nodeType === 'module') {
            details = this._moduleNodeDetails(nodePath)
        } else {
            details = this._attributeNodeDetails(nodePath)
        }
        details.nodeType = nodeType
        return details
    }

    _topNodeChildren() {
        return keys(this.ciods)
    }

    _ciodNodeChildren([ciodId]) {
        const query = { ciodId: ciodId }
        const relationships = filter(this.ciodToModule, query)
        return map(relationships, 'moduleId')
    }

    _moduleNodeChildren([ciodId, moduleId]) {
        const query = { moduleId: moduleId }
        const allChildren = filter(this.moduleToAttribute, query)
        const immediateChildren = filter(
            allChildren,
            (c) => c.path.split(':').length === 2
        )
        return map(immediateChildren, 'path').map((p) => {
            return last(p.split(':'))
        })
    }

    _attributeNodeChildren([ciodId, moduleId, ...attributePath]) {
        const query = { moduleId: moduleId }
        const allChildren = filter(this.moduleToAttribute, query)
        const allChildrenAtDepth = filter(allChildren, (c) => {
            return c.path.split(':').length === attributePath.length + 2
        })
        const parentsAttributePath = [moduleId].concat(attributePath).join(':')
        const childrenOfAttribute = filter(allChildrenAtDepth, (r) => {
            return r.path.startsWith(parentsAttributePath)
        })
        return map(childrenOfAttribute, (r) => {
            return last(r.path.split(':'))
        })
    }

    _topNodeDetails() {
        return {
            name: 'CIODs',
            fullName: 'All CIODs',
            externalReferences: [],
            canonicalUrl: baseSiteUrl,
        }
    }

    _ciodNodeDetails(nodePath) {
        let [ciodId] = nodePath
        let details = this.ciods[ciodId]
        if (details !== undefined) {
            details.fullName = `${details.name} CIOD`
            details.externalReferences = []
            details.canonicalUrl = baseSiteUrl + '/' + ciodId
            return details
        } else {
            throw new InvalidDicomNode(nodePath)
        }
    }

    _moduleNodeDetails(nodePath) {
        let [ciodId, moduleId] = nodePath
        const query = { moduleId: moduleId, ciodId: ciodId }
        const relationship = find(this.ciodToModule, query)
        if (relationship !== undefined) {
            let details = this._denormalizeCiodToModule(relationship)
            details.fullName = `${details.name} Module`
            details.externalReferences = []
            details.canonicalUrl = this.canonicalModuleLinks[moduleId]
            return details
        } else {
            throw new InvalidDicomNode(nodePath)
        }
    }

    _attributeNodeDetails(nodePath) {
        let [ciodId, moduleId, ...attributePath] = nodePath
        const query = {
            moduleId: moduleId,
            path: [moduleId].concat(attributePath).join(':'),
        }
        const relationship = find(this.moduleToAttribute, query)
        if (relationship !== undefined) {
            let details = this._denormalizeModuleToAttribute(relationship)
            if (details.retired != 'Y') {
                details.fullName = `${details.name} Attribute`
            } else {
                details.fullName = `${details.name} Attribute (Retired)`
            }
            details.canonicalUrl = this.canonicalAttributeLinks[
                relationship.tag
            ]
            return details
        } else {
            throw new InvalidDicomNode(nodePath)
        }
    }

    _denormalizeCiodToModule(relationship) {
        const module = this.modules[relationship.moduleId]
        return extend({}, module, relationship)
    }

    _denormalizeModuleToAttribute(relationship) {
        const id = last(relationship.path.split(':'))
        return extend({}, this.attributes[id], relationship)
    }
}
