import React from 'react'

import './SiteFooter.scss'

export default class SiteFooter extends React.Component {
    render() {
        const currentYear = new Date().getFullYear()
        let copyrightYears
        if (currentYear > 2016) {
            copyrightYears = `2016 \u2013 ${currentYear}`
        } else {
            copyrightYears = '2016'
        }
        return (
            <footer className="site-footer text-very-muted">
                <div className="links-container">
                    <span className="text-small">
                        &copy; {copyrightYears} Innolitics, LLC.
                    </span>
                    <ul className="link-list">
                        <li>
                            <a href="/terms">Terms</a>
                        </li>
                        <li>
                            <a href="//github.com/innolitics/dicom-standard/issues/new?labels=enhancement%2C+browser&template=feature-request.md">
                                Suggest
                                <span className="hidden-sm-down"> a Feature</span>
                            </a>
                        </li>
                        <li>
                            <a href="//github.com/innolitics/dicom-standard/issues/new?labels=browser%2C+bug&template=bug-report.md">
                                Report
                                <span className="hidden-sm-down"> an Error</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://innolitics.com/about/contact/">
                                Contact
                                <span className="hidden-xs-down"> Innolitics</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <p className="text-small">
                    Built with{' '}
                    <a
                        className="heart"
                        href="https://innolitics.com/about/mission-and-values/#do-quality-work"
                    ></a>{' '}
                    by <a href="https://innolitics.com">Innolitics</a>, a team of
                    medical imaging software developers.
                </p>
                <p className="text-small">
                    Data synced with official DICOM standard on 18 April 2024. The
                    DICOM Standard is under continuous maintenance, and the current
                    official version is available at{' '}
                    <a href="//dicomstandard.org/current/">
                        http://www.dicomstandard.org/current/
                    </a>
                    . DICOM Parts 3, 4, and 6, © NEMA. Please note that the most
                    recent PDF version of the standard is the official reference,
                    and should checked when making technical decisions.
                </p>
            </footer>
        )
    }
}
