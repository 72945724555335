import React from 'react'

import { Meta, SiteFooter } from '.'

import 'stylesheets/utils.scss'

export default class TimeoutPage extends React.Component {
    render() {
        return (
            <div className="content m-a-1">
                <Meta
                    title="Response Timeout"
                    description="Took too long to load page."
                />
                <a className="text-muted text-small" href="/">
                    Back to Landing Page
                </a>
                <h1>Response Timeout</h1>
                <p>It took too long to load the page.</p>
                <p>Please try again.</p>
                <p>If the error persists, please contact us and let us know!</p>
                <SiteFooter />
            </div>
        )
    }
}
