import { history } from 'config'
import { getNodePath } from 'utils/urlActions'

export const OPEN_NODE = 'OPEN_NODE'
export const openNode = (path) => ({
    type: OPEN_NODE,
    path,
})

export const CLOSE_NODE = 'CLOSE_NODE'
export const closeNode = (path) => ({
    type: CLOSE_NODE,
    path,
})

export const TOGGLE_NODE = 'TOGGLE_NODE'
export const toggleNode = (path) => ({
    type: TOGGLE_NODE,
    path,
})

export const selectNode = (node) => {
    return () => {
        history.replace(getNodePath(node))
    }
}

export const SET_SCROLL = 'SET_SCROLL'
export const setScroll = (scroll) => ({
    type: SET_SCROLL,
    scroll,
})
