import React from 'react'

import { ErrorPageContent, Meta, SiteFooter, Status } from '.'

import 'stylesheets/utils.scss'

export default class ErrorPage extends React.Component {
    render() {
        return (
            <Status code={500} className="content m-a-1">
                <div className="content m-a-1">
                    <Meta
                        title="Server Error"
                        description="Sorry about that. Go DICOM go!"
                    />
                    <a className="text-muted text-small" href="/">
                        Back to Landing Page
                    </a>
                    <h1>Something went wrong.</h1>
                    <ErrorPageContent />
                    <SiteFooter />
                </div>
            </Status>
        )
    }
}
