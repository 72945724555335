import React from 'react'

// TODO: find a better way; e.g. by allowing the `dicomTableTree` to produce react elements
export default class NodeName extends React.Component {
    render() {
        const { name, includeTag = true } = this.props

        let indexOfClosingParen = name.indexOf(')')
        let isAttribute = indexOfClosingParen !== -1
        if (isAttribute) {
            let tagPart = name.slice(0, indexOfClosingParen + 1)
            let namePart = name.slice(indexOfClosingParen + 2)
            if (includeTag) {
                return (
                    <span>
                        <span className="fixed-width-tag hidden-sm-down">
                            {tagPart}{' '}
                        </span>
                        {namePart}
                    </span>
                )
            } else {
                return <span>{namePart}</span>
            }
        } else {
            return <span>{name}</span>
        }
    }
}
