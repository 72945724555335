// TODO: split this into a separate template

import React from 'react'
import { withRouter } from 'react-router-dom'

import { Meta, SiteFooter, Status } from '.'

import 'stylesheets/utils.scss'

@withRouter
export default class MissingPage extends React.Component {
    render() {
        const { location } = this.props

        return (
            <Status code={404}>
                <div className="content m-a-1">
                    <Meta
                        title="Unknown Page"
                        description="Ooops! Maybe you have a typo."
                    />
                    <a className="text-muted text-small" href="/">
                        Back to Landing Page
                    </a>
                    <h1>Unknown Page</h1>
                    <p>It seems you were looking for the page</p>
                    <pre className="m-l-1 text-secondary">
                        {location.pathname}
                    </pre>
                    <p>
                        ...but we can't find it anywhere! Perhaps there is a
                        typo in the URL?
                    </p>
                    <p>
                        Here are some other DICOM related pages you may be
                        interested in:
                    </p>
                    <ul>
                        <li>
                            <a href="/ciods">Directory of all DICOM CIODs</a>
                        </li>
                        <li>
                            <a href="https://innolitics.com/articles/dicom-i-facilitating-interoperability/">
                                Introductory article about DICOM
                            </a>
                        </li>
                    </ul>
                    <p>We hope you find what you were looking for!</p>
                    <SiteFooter />
                </div>
            </Status>
        )
    }
}
