import * as dcmjs from 'dcmjs'
import { AnyAction } from 'redux'

import { AnalyzeState } from './typeUtils'
import {
    UPLOAD_FILE,
    RESET_ALL,
    UPDATE_PENDING_CHANGE,
    CLEAR_CHANGES,
    COMMIT_CHANGES,
    CREATE_DOWNLOAD,
    QUEUE_EXAMPLE,
    EXAMPLE_DOWNLOAD_STARTED,
    EXAMPLE_DOWNLOAD_FINISHED,
    EXAMPLE_DOWNLOAD_FAILED,
} from './actions'
import { applyChanges } from './dataset'

const initialState = {
    dataset: null,
    pending_changes: {},
    download: {
        uri: null,
        blob: null
    }
}

const getModifiedFilename = (originalFilename: string): string => {
    const newFilenameParts = originalFilename.split('.')
    const extensionPresent = newFilenameParts.length > 1
    if (extensionPresent) {
        let baseFileName = newFilenameParts.slice(0, -1).join('.')
        baseFileName += '-modified'
        const [extension] = newFilenameParts.slice(-1)
        return baseFileName + '.' + extension
    }
    return newFilenameParts[0] + '-modified'
}

const getValueFromError = (err, value = null) => {
    const deconstructed_message = err.message.split(', ')
    if (value) {
        return deconstructed_message
            .filter(el => el.includes(value))[0]
            .split(`${value}: `)[1]
    } else {
        return deconstructed_message[0]
    }
}

export default (state: AnalyzeState = initialState, action: AnyAction) => {
    switch (action.type) {
        case UPLOAD_FILE:
            return {
                ...state,
                dataset: action.dataset,
                pending_changes: {}
            }
        case RESET_ALL:
            return {
                ...state,
                dataset: null,
                pending_changes: {}
            }
        case UPDATE_PENDING_CHANGE:
            return {
                ...state,
                pending_changes: {
                    ...state.pending_changes,
                    [action.tagPath.join('_')]: action.value
                }
            }
        case COMMIT_CHANGES:
            return {
                ...state,
                dataset: applyChanges(state.dataset, state.pending_changes),
                pending_changes: {}
            }
        case CLEAR_CHANGES:
            return {
                ...state,
                pending_changes: {}
            }
        case CREATE_DOWNLOAD: {
            const newFilename = getModifiedFilename(state.dataset.fileName)
            try {
                const blob = new File(
                    [state.dataset.data.write()],
                    newFilename,
                    {
                        type: 'application/dicom'
                    }
                )
                const uri = URL.createObjectURL(blob)
                return { ...state, download: { uri, blob } }
            } catch (err) {
                const error_message = getValueFromError(err)
                const element_text = getValueFromError(err, 'value')
                const vr = getValueFromError(err, 'vr')
                const invalid = Object.values(state.dataset.elements).filter(
                    value => {
                        return value.formattedText === element_text
                    }
                )
                const invalid_names = invalid.map(el => el.tagName)
                alert(
                    `${
                        invalid.length
                    } of the values in this file produced the error: ${error_message}.\n\nPlease re-format fields:\n- ${invalid_names.join(
                        '\n- '
                    )}\n\nto match value representation ${vr} to edit this file.`
                )
                return {
                    ...state,
                    dataset: { ...state.dataset, invalid: invalid }
                }
            }
        }
        case QUEUE_EXAMPLE: {
            return {
                ...state,
                queuedExample: action.payload,
                downloadingExample: false,
            }
        }
        case EXAMPLE_DOWNLOAD_STARTED: {
            return {
                ...state,
                downloadingExample: true,
            }
        }
        case EXAMPLE_DOWNLOAD_FAILED: {
            return {
                ...state,
                queuedExample: undefined,
                downloadingExample: false,
            }
        }
        case EXAMPLE_DOWNLOAD_FINISHED: {
            return {
                ...state,
                queuedExample: undefined,
                downloadingExample: false,
            }
        }
        default:
            return state
    }
}
