import { getApiUrl } from 'utils/api'
import { timeout } from 'utils/asyncDecorators'

export const REQUEST_REFERENCE = 'REQUEST_REFERENCE'
export const requestReference = (url) => ({
    type: REQUEST_REFERENCE,
    url,
})

export const REQUEST_REFERENCE_FAILED = 'REQUEST_REFERENCE_FAILED'
export const requestReferenceFailed = (url) => ({
    type: REQUEST_REFERENCE_FAILED,
    url,
})

export const RECEIVE_REFERENCE = 'RECEIVE_REFERENCE'
export const receiveReference = (url, content) => ({
    type: RECEIVE_REFERENCE,
    url,
    content: content,
})

export const loadExternalReference = (url) => {
    return async (dispatch) => {
        dispatch(requestReference(url))
        try {
            const response = await timeout(fetch, 10000)(getApiUrl(url))
            const json = await response.json()
            dispatch(receiveReference(url, json))
        } catch (error) {
            console.log(`ERROR: Failed loading external reference: ${error}`)
            dispatch(requestReferenceFailed(url))
        }
    }
}
