// TODO: split this into a separate template
// NOTE: this page cannot import <Link />

import React from 'react'

import { ErrorPageContent, Meta, SiteFooter } from '.'

import 'stylesheets/utils.scss'

export default class ServerError extends React.Component {
    render() {
        return (
            <div className="content m-a-1">
                <Meta
                    title="Server Error"
                    description="Sorry about that. Go DICOM go!"
                />
                <a className="text-muted text-small" href="/">
                    Back to Landing Page
                </a>
                <h1>Server Error!</h1>
                <ErrorPageContent />
                <SiteFooter />
            </div>
        )
    }
}
