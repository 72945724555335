export const expandTree = (childPaths, rootAbsolutePath = []) => {
    const children = childPaths(rootAbsolutePath)
    return children.map((relativePath) => {
        const absolutePath = rootAbsolutePath.concat(relativePath)
        return {
            path: relativePath,
            children: expandTree(childPaths, absolutePath),
        }
    })
}

export const flattenTree = (rootChildren, rootAbsolutePath = []) => {
    let table = []
    rootChildren.forEach((n) => {
        const absolutePath = rootAbsolutePath.concat(n.path)
        table.push(absolutePath)
        table.push.apply(table, flattenTree(n.children, absolutePath))
    })
    return table
}

export const getNodeParent = (node) => node.slice(0, -1)
