import React from 'react'

export default class SearchResults extends React.Component {
    render() {
        const {
            resultsStart,
            resultsEnd,
            numResults,
            results,
            displayResult,
        } = this.props
        
        return (
            <div>
                <p className="text-small text-muted">
                    Showing {resultsStart} to {resultsEnd} of {numResults} results.
                </p>
                <ul className="search-results">{results.map(displayResult)}</ul>
            </div>
        )
    }
}
