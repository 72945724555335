import React from 'react'
import classNames from 'classnames'

import 'stylesheets/arrows.scss'

export default class Arrow extends React.Component {
    render() {
        const { node, onArrowClick } = this.props

        const onClick = (e) => {
            e.stopPropagation()
            onArrowClick(node.path)
        }

        let arrowClassName = classNames({
            arrow: node.hasChildren,
            open: node.open,
        })

        const indentationWidthPx = 25
        const indentationSpace = (node.path.length - 1) * indentationWidthPx

        return (
            <span
                className="arrow-holder"
                style={{ marginLeft: indentationSpace }}
                onClick={onClick}
            >
                <span className={arrowClassName} />
            </span>
        )
    }
}
