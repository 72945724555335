import React from 'react'

export default class Spinner extends React.Component {
    render() {
        return (
            <span className="lds-spinner">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
            </span>
        )
    }
}
