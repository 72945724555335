import React from 'react'

import './Banner.scss'

export default class Banner extends React.Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
        this.state = { showBanner: true }
    }

    handleClick = (e) => {
        this.setState({ showBanner: false })
    }

    render() {
        return (
            this.state.showBanner && (
                <div className="dismissable-banner">
                    {this.props.content}
                </div>
            )
        )
    }
}
