import React from 'react'
import { connect } from 'react-redux'

import { Spinner } from 'modules/core/components'
import { SearchPaginator, SearchResults } from '../components'
import { search } from '../actions'

import './SearchPane.scss'

const mapStateToProps = ({ search: searchState }) => ({ searchState })

const mapDispatchToProps = { search }

@connect(mapStateToProps, mapDispatchToProps)
export default class SearchPane extends React.Component {
    componentDidMount() {
        this.searchBar.focus()
    }

    handleChange(event) {
        const {
            search,
            searchState: { size },
        } = this.props

        search(event.target.value, size)
    }

    handleSubmit(event) {
        const {
            search,
            searchState: { query, size },
        } = this.props

        event.preventDefault()
        search(query, size)
    }

    render() {
        const {
            search,
            displayResult,
            searchState: {
                pending,
                results,
                numResults,
                page,
                size,
                error,
                query,
            },
        } = this.props

        const resultsStart = Math.min((page - 1) * size + 1, numResults)
        const resultsEnd = Math.min(page * size, numResults)

        let searchResultsContent

        if (pending) {
            searchResultsContent = (
                <p>
                    Searching... <Spinner />
                </p>
            )
        } else if (error) {
            searchResultsContent = <p>{error}</p>
        } else if (!query) {
            searchResultsContent = (
                <p>Search for DICOM attributes, modules, or CIODs.</p>
            )
        } else if (numResults === 0) {
            searchResultsContent = <p>No results found.</p>
        } else {
            searchResultsContent = (
                <div>
                    <SearchResults
                        resultsStart={resultsStart}
                        resultsEnd={resultsEnd}
                        numResults={numResults}
                        results={results}
                        displayResult={displayResult}
                    />
                    <SearchPaginator
                        page={page}
                        size={size}
                        numResults={numResults}
                        query={query}
                        onSearch={search}
                    />
                </div>
            )
        }

        return (
            <div className="search-pane">
                <form
                    className="search-form"
                    onSubmit={this.handleSubmit.bind(this)}
                >
                    <input
                        type="search"
                        className="search-bar"
                        value={query}
                        onChange={this.handleChange.bind(this)}
                        ref={(input) => (this.searchBar = input)}
                    />
                    <input
                        type="submit"
                        className="search-submit"
                        value="Search"
                    />
                </form>

                {searchResultsContent}
            </div>
        )
    }
}
