import React from 'react'
import { connect } from 'react-redux'

import { loadExternalReference } from '../actions'
import { createMarkup } from 'utils/react'

import './ReferencedSection.scss'

const mapStateToProps = ({ externalReferences }, { url }) => ({
    externalReferences,
    url,
})

const mapDispatchToProps = { loadExternalReference }

@connect(mapStateToProps, mapDispatchToProps)
export default class ReferencedSection extends React.Component {
    // It's unfortunate we have to include this guard here, but it exists
    // to prevent `loadExternalReference` from firing off a dummy
    // request/response pair of actions if a reference already exists in
    // the state.
    componentDidMount() {
        const { externalReferences, loadExternalReference } = this.props

        if (externalReferences[this.props.url] === undefined) {
            loadExternalReference(this.props.url)
        }
    }

    render() {
        const { url, externalReferences } = this.props
        const state = externalReferences[url] && externalReferences[url].state
        if (state === 'loading' || state === undefined) {
            return <p>Loading external reference ... </p>
        } else if (state === 'loaded') {
            return (
                <div
                    className="external-reference"
                    dangerouslySetInnerHTML={createMarkup(
                        externalReferences[url].content
                    )}
                />
            )
        } else {
            return (
                <p>An error occurred while loading the external reference.</p>
            )
        }
    }
}
