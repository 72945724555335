import { combineReducers } from 'redux'
import { isEqual } from 'lodash'

import { OPEN_NODE, CLOSE_NODE, TOGGLE_NODE, SET_SCROLL } from './actions'
import { containsArray } from 'utils'

export const openNodes = (state = [[]], action) => {
    switch (action.type) {
        case OPEN_NODE:
            return openNode(state, action.path)
        case CLOSE_NODE:
            return closeNode(state, action.path)
        case TOGGLE_NODE:
            return toggleNode(state, action.path)
        default:
            return state
    }
}

const openNode = (openNodes, path) => {
    const nodeWithParents = [[], ...path.map((id, i) => path.slice(0, i + 1))]
    return [
        ...openNodes,
        ...nodeWithParents.filter((node) => !containsArray(openNodes, node)),
    ]
}

const closeNode = (openNodes, path) => {
    return openNodes.filter((p) => !isEqual(p, path))
}

const toggleNode = (openNodes, path) => {
    return containsArray(openNodes, path)
        ? closeNode(openNodes, path)
        : openNode(openNodes, path)
}

export const scroll = (state = false, action) => {
    switch (action.type) {
        case SET_SCROLL:
            return action.scroll
        default:
            return state
    }
}

export default combineReducers({
    openNodes,
    scroll,
})
