const baseApiUrlClient = '/api/v1/references/'

let baseApiUrlServer
if (process.env.BASE_API_URL) {
    baseApiUrlServer = process.env.BASE_API_URL + baseApiUrlClient
} else {
    baseApiUrlServer = `http://localhost:${process.env.PORT}${baseApiUrlClient}`
}

export const getApiUrl = (reference) => {
    const urlReference = encodeURIComponent(reference)
    const baseApiUrl = ON_SERVER ? baseApiUrlServer : baseApiUrlClient
    return baseApiUrl + urlReference
}
