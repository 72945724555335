import React from 'react'

import { NodeLink } from '.'
import { getNodeParent } from '../utils'

export default class NodePath extends React.Component {
    render() {
        const { node, standard, onNodeClick, suffix } = this.props
        let name = (
            <NodeLink node={node} standard={standard} onNodeClick={onNodeClick} />
        )
        const accumulatedPath = suffix ? (
            <span>
                {name} &raquo; {suffix}
            </span>
        ) : (
            name
        )

        return node.length <= 1 ? (
            accumulatedPath
        ) : (
            <NodePath
                node={getNodeParent(node)}
                standard={standard}
                onNodeClick={onNodeClick}
                suffix={accumulatedPath}
            />
        )
    }
}
