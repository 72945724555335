import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import dcmjs from 'dcmjs'
import { Dataset, DicomElement } from '../dataset'
import { uploadFile, resetAll, downloadModifiedFile } from '../actions'
import { getNodeParent } from '../../tree/utils'
import standard from '../../../utils/dicomStandard' // TODO use JSONSchema for type definition of standard

import { AnalyzeState, Dict } from '../typeUtils'
import { Entry } from '../components'
import sendGA from '../../../external/googleAnalytics'

import './AnalyzePane.scss'

const mapStateToProps = (state, props) => {
    const {
        analyze: { dataset, download, queuedExample, downloadingExample }
    } = state
    const {
        focusedNode,
    } = props
    return { dataset, download, focusedNode, queuedExample, downloadingExample }
}


const mapDispatchToProps = {
    uploadFile,
    resetAll,
    downloadModifiedFile,
}

interface AnalyzePaneProps {
    dataset: Dataset
    openFilePath: (
        node: string[],
        nodeToOpen: string[]
    ) => (event: Event, first?: boolean) => void
    uploadFile: any
    resetAll: any
    downloadModifiedFile: () => void
    download: AnalyzeState['download']
    focusedNode: string[]
    queuedExample?: string
    downloadingExample?: boolean
}
interface AnalyzePaneState {
    errorMessage: string
    dragover: false
    invalid: []
    timerTick: number
}

@connect(mapStateToProps, mapDispatchToProps)
export default class AnalyzePane extends React.Component<
    AnalyzePaneProps,
    AnalyzePaneState
> {
    timerHandle: NodeJS.Timeout;

    constructor(props: AnalyzePaneProps) {
        super(props)
        this.state = {
            errorMessage: '',
            dragover: false,
            invalid: props.dataset?.invalid,
            timerTick: 0
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataset !== this.props.dataset) {
            const { dataset } = this.props
            if (dataset) {
                const invalidElements = document.getElementsByClassName('invalid')
                invalidElements[0]?.scrollIntoView()
            }
        }
    }

    componentDidMount() {
        this.timerHandle = setInterval(
            () => { this.setState((prevState) => ({ timerTick: (prevState.timerTick + 1) % 3})) },
            300,
        )
    }

    componentWillUnmount() {
        clearInterval(this.timerHandle)
    }

    render() {
        let fileContent = []
        const { dataset } = this.props
        const { dragover } = this.state
        const selectOptions = _.uniq(_.map(Object.values(standard.sops), (sop: any) => sop.ciod)).sort()
        // TODO Fix this with type definition of standard
        const hasDataset = dataset !== null
        const buildFileContent = () => {
            const elements = Object.values(dataset.elements)
            let toAdd = []
            toAdd.push(this.formatOutput(elements, 0, elements.length))
            fileContent.push(
                <ul className="file-content" key="file-content">
                    {toAdd}
                </ul>
            )
        }

        hasDataset && buildFileContent()

        const formClassName =
            (dragover ? 'file-wrapper dragover' : 'file-wrapper') +
            (hasDataset ? ' dataset' : '')
        const formPrompt = hasDataset ? (
            <p>
                <span className="view-file">
                    {`Viewing ${this.props.dataset.fileName}`}
                </span>
                <label className="reset" onClick={this.props.resetAll}>
                    Clear file
                </label>
            </p>
        ) : (
            <div>
                <input
                    type="file"
                    className="input-file"
                    id="dicom-file"
                    onChange={this.handleFileSelection}
                />
                <div className="analyze-form-prompt">
                    <p className="content">
                        Drag and drop a DICOM file here to view its contents{' '}
                    </p>
                    <p>
                        <label htmlFor="dicom-file" className="file-label">
                            {' '}
                            Or click to choose a file
                        </label>
                    </p>
                    {this.state.errorMessage !== '' && (
                        <div className="error-message">
                            <div className="overflow">
                                {this.state.errorMessage}
                            </div>
                            <div>Are you sure it is a valid Dicom file? </div>
                        </div>
                    )}
                </div>
            </div>
        )

        const numMissing = hasDataset ? dataset.missing.length : 0
        const missingText =
            numMissing === 1
                ? '1 required attribute'
                : numMissing > 1
                ? `${numMissing} required attributes`
                : ''

        let loadingEllipse: string;
        if (this.state.timerTick === 0) {
            loadingEllipse = '.'
        } else if (this.state.timerTick === 1) {
            loadingEllipse = '..'
        } else {
            loadingEllipse = '...'
        }

        const formElement = this.props.downloadingExample || this.props.queuedExample ? (
            <div className={formClassName}>
                <span className='loading-example'>Loading {this.props.queuedExample}{loadingEllipse}</span>
            </div>
        ) : (
            <form
                className={formClassName}
                onDrag={this.onDrag.bind(this)}
                onDragStart={this.onDrag.bind(this)}
                onDragOver={this.onDragEnter.bind(this)}
                onDragEnter={this.onDragEnter.bind(this)}
                onDragEnd={this.onDragLeave.bind(this)}
                onDragLeave={this.onDragLeave.bind(this)}
                onDrop={this.onDrop.bind(this)}
            >
                {formPrompt}
            </form>
        )


        return (
            <div
                className="analyze-pane"
            >
                <div className="comment">
                    We do not collect any information from edited files.
                </div>
                {formElement}
                <span className="actions">
                    <div>
                        Please{' '}
                        <a href="//github.com/innolitics/dicom-standard/issues/new/choose">
                            {' '}
                            open an issue{' '}
                        </a>
                        if you find a bug or have any suggestions to improve the
                        file editor.
                    </div>
                    {hasDataset && (
                        <button
                            onClick={this.props.downloadModifiedFile.bind(this)}
                            className="export-button"
                        >
                            Export DICOM
                        </button>
                    )}
                </span>
                <div className="detected-ciod-section">
                    {hasDataset && (
                        <div className="ciod-select">
                            <h2 className="text-secondary"> CIOD:</h2>
                                <select id="entry-select" onChange={e => this.handleCiodChange(e)}>
                                    {this.buildSelectContent(selectOptions)}
                                </select>
                        </div>
                    )}
                    {numMissing > 0 ? (
                        <p className="required-attribute-text text-small text-muted">
                            <span>&#9888; We found </span>
                            <span className="missing-text">{missingText}</span>
                            <span> not included in the dicom file</span>
                        </p>
                    ) : (
                        ''
                    )}
                </div>
                {fileContent}
            </div>
        )
    }

    formatOutput = (
        elements: DicomElement[],
        start: number,
        end: number,
        matchDepth: number = 0
    ): JSX.Element[] => {
        const depths = _.map(elements, 'depth')
        let idx = start
        let fileContent = []
        while (idx < end) {
            const element = elements[idx]
            const isInvalid = !!this.props.dataset.invalid.includes(element)
            const {
                tagName,
                depth,
                uniquePath,
                index
            } = element
            if (tagName !== null && depth === matchDepth) {
                if (index !== -1) {
                    fileContent.push(
                        <li
                            className="item-num"
                            key={uniquePath.join() + index.toString()}
                        >
                            Item {index + 1}
                        </li>
                    )
                }
                fileContent.push(
                    <Entry
                        isInvalid={isInvalid}
                        key={uniquePath.join()}
                        element={element}
                        openFilePath={this.props.openFilePath}
                    />
                )
            }
            let midIndex = start
            if (depth > matchDepth) {
                midIndex = this.findMid(depths, matchDepth, idx, end)
                const children = this.formatOutput(
                    elements,
                    idx,
                    midIndex,
                    depth
                )
                fileContent.push(
                    <ul className="file-content-body" key={idx}>
                        {children}
                    </ul>
                )
                idx = midIndex - 1
            }
            idx = idx + 1
        }
        return fileContent
    }

    findMid = (
        depths: number[],
        value: number,
        start: number,
        end: number
    ): number => {
        for (let i = start; i < end; i++) {
            if (depths[i] !== value && depths[i + 1] === value) {
                return i + 1
            }
        }
        return end
    }

    buildSelectContent = (selectOptions) => {
        const { focusedNode } = this.props
        const focusedNodeDetails = standard.nodeDetails(focusedNode)
        return [...selectOptions.map((iod) => {
            return this.props.dataset.imageType === iod ? <option key={iod} selected value={iod}>{iod}</option> : <option key={iod} value={iod}>{iod}</option>
        })]
    }

    handleFileSelection = (event, isDrag = false) => {
        event.stopPropagation()
        event.preventDefault()
        const file = isDrag
            ? event.dataTransfer.files[0]
            : event.target.files[0]

        const reader = new FileReader()
        reader.onload = () => {
            let arrayBuffer = reader.result
            try {
                const dicomDict = dcmjs.data.DicomMessage.readFile(arrayBuffer)
                const dataset = new Dataset(
                    dicomDict,
                    file.name,
                )
                /* Must reset redux store to ensure the File Editor
                does not show pending changes from previous
                file when it is the same dataset */
                this.props.resetAll()
                this.props.uploadFile(dataset)

                if (dataset.ciodId) {
                    const handleFilePath = this.props.openFilePath(
                        [dataset.ciodId],
                        [dataset.ciodId]
                    )
                    handleFilePath(event, true)
                }
                sendGA('send', 'event', 'file', 'upload')
                this.setState({ errorMessage: '' })
            } catch (error) {
                console.log(error)
                this.props.resetAll()
                const parseError =
                    error instanceof TypeError ||
                    (typeof error === 'string' &&
                        error.includes('dicomParser.readPart10Header'))
                const errorMessage = parseError
                    ? `Unable to parse ${file.name}.`
                    : ''
                this.setState({ errorMessage: errorMessage })
            }
        }

        if (file) {
            reader.readAsArrayBuffer(file)
        } else {
            this.props.resetAll()
        }
    }

    handleCiodChange = e => {
        e.preventDefault()
        const entrySelect = document.getElementById(
            'entry-select'
        ) as HTMLInputElement
        const imageTypeOverride = entrySelect.value
        this.setState({ dropdown: false })
        const { data, fileName, origImageType } = this.props.dataset
        const dataset = new Dataset(data, fileName, imageTypeOverride)
        this.props.uploadFile(dataset)
        const handleFilePath = this.props.openFilePath(
            [dataset.ciodId],
            [dataset.ciodId]
        )
        handleFilePath(e, true)
    }


    onDrag(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    onDragEnter(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({ dragover: true })
    }

    onDragLeave(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({ dragover: false })
    }

    onDrop(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({ dragover: false })

        const { files } = e.dataTransfer
        if (files.length > 0) {
            this.handleFileSelection(e, true)
        }
    }
}
