import { uniqueId, findIndex } from 'lodash'

import { ADD_MESSAGE, DISMISS_MESSAGE } from './actions'

export default (messageList = [], action) => {
    switch (action.type) {
        case ADD_MESSAGE:
            return addMessage(messageList, action)
        case DISMISS_MESSAGE:
            return dismissMessage(messageList, action)
        default:
            return messageList
    }
}

const addMessage = (messageList, action) => {
    return messageList.concat([
        {
            level: action.level,
            content: action.content,
            id: uniqueId('message-'),
        },
    ])
}

const dismissMessage = (messageList, action) => {
    const indexOfRemovedMessage = findIndex(messageList, { id: action.id })
    if (indexOfRemovedMessage !== -1) {
        const updatedMessageList = messageList.slice()
        updatedMessageList.splice(indexOfRemovedMessage, 1)
        return updatedMessageList
    } else {
        throw new Error(
            `Attempt to dismiss non-existent message at id ${action.id}`
        )
    }
}
