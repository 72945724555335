import React from 'react'
import { connect } from 'react-redux'

import { Message } from '../components'
import { dismissMessage } from '../actions'

const MAX_MESSAGES_DISPLAYED = 5

const mapStateToProps = ({ messages }) => ({ messages })

const mapDispatchToProps = { dismissMessage }

@connect(mapStateToProps, mapDispatchToProps)
export default class MessageList extends React.Component {
    render() {
        const { messages = [], buttonDismiss } = this.props
        if (messages.length > 0) {
            return (
                <div className="message-list">
                    {showTopMessages(messages, buttonDismiss)}
                    {messages.length > MAX_MESSAGES_DISPLAYED ? (
                        showRemainingMessages(messages.length)
                    ) : (
                        <p />
                    )}
                </div>
            )
        } else {
            return null
        }
    }
}

const showRemainingMessages = (messagesLength) => {
    const hiddenMessages = messagesLength - MAX_MESSAGES_DISPLAYED
    return (
        <p>
            {hiddenMessages} more {pluralOrSingularMessage(messagesLength)}
        </p>
    )
}

const pluralOrSingularMessage = (messagesLength) => {
    return messagesLength - MAX_MESSAGES_DISPLAYED === 1
        ? 'message'
        : 'messages'
}

const showTopMessages = (messages, buttonDismiss) => {
    return messages
        .slice(0, MAX_MESSAGES_DISPLAYED)
        .map((m) => (
            <Message
                key={m.id}
                id={m.id}
                level={m.level}
                content={m.content}
                buttonDismiss={buttonDismiss}
            />
        ))
}
