import React from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'

import standard from 'utils/dicomStandard'
import { getNodePath } from 'utils/urlActions'
import { Node } from '../components'
import { toggleNode } from '../actions'
import { getVisibleNodesTable } from '../selectors'

import './TreeTable.scss'

const mapStateToProps = ({ tree: { openNodes }, panes: { open } }) => {
    const nodes = getVisibleNodesTable(standard, openNodes)
    return { nodes, open }
}

const mapDispatchToProps = { toggleNode }

@connect(mapStateToProps, mapDispatchToProps)
export default class TreeTable extends React.Component {
    handleKeyDown = this.keyDownHandler.bind(this)
    focusedNodeIndex = null

    keyDownHandler(e) {
        const { focusedNode, toggleNode, nodes, selectNode } = this.props
        if (["ArrowUp", "ArrowDown"].includes(e.key)) {
            e.preventDefault()
        }

        if (e.key === "ArrowRight") {
            toggleNode(focusedNode)
        } else if (e.key === "ArrowUp") {
            const last = (this.focusedNodeIndex !== 0 ? this.focusedNodeIndex : nodes.length) - 1
            selectNode(nodes[last].path)
        } else if (e.key === "ArrowDown") {
            const next = this.focusedNodeIndex < nodes.length - 1 ? this.focusedNodeIndex + 1 : 0
            selectNode(nodes[next].path)
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleKeyDown)
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeyDown)
    }

    render() {
        const {
            nodes,
            toggleNode,
            focusedNode,
            givenElements,
            onNodeClick,
            selectNode,
            missingNodes,
            ciodId,
        } = this.props

        return (
            <table className="tree-table">
                <tbody>
                    {nodes.map((n, i) => {
                        isEqual(n.path, focusedNode) && (this.focusedNodeIndex = i)
                        return <Node
                            key={i}
                            node={n}
                            focusedNode={focusedNode}
                            onArrowClick={toggleNode}
                            onNodeClick={onNodeClick}
                            selectNode={selectNode}
                            missingNodes={missingNodes}
                            ciodId={ciodId}
                            givenElements={givenElements}
                        />
                    })}
                </tbody>
            </table>
        )
    }
}
