import React from 'react'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { openSidePane, toggleSidePane } from '../actions'
import withDevice, { Device } from 'modules/core/hocs/withDevice'
import panes from 'stylesheets/panes.scss'

const detailPaneWidthLaptop = parseInt(panes.detailPaneWidthLaptop)
const detailPaneWidthDesktop = parseInt(panes.detailPaneWidthDesktop)
const panePrimaryMinWidth = parseInt(panes.panePrimaryMinWidth)
const paneSecondaryMinWidth = parseInt(panes.paneSecondaryMinWidth)
const paneBorderWidth = parseInt(panes.paneBorderWidth)
const paneTransitionDuration = parseInt(panes.paneTransitionDuration)

const DETAIL_PANE_WIDTH = {
    [Device.phone]: null,
    [Device.tablet]: null,
    [Device.laptop]: detailPaneWidthLaptop,
    [Device.desktop]: detailPaneWidthDesktop,
}

const mapStateToProps = ({ panes: { open, selected } }) => ({ open, selected })

const mapDispatchToProps = { openSidePane, toggleSidePane }

@withDevice
@connect(mapStateToProps, mapDispatchToProps)
export default class SidePane extends React.Component {
    constructor(props) {
        super(props)

        const { device } = this.props

        this.state = {
            isDragging: false,
            width: DETAIL_PANE_WIDTH[device],
        }
    }

    state = { isDragging: false, width: 495 }

    handleTabClick(name, event) {
        const { openSidePane } = this.props

        event.preventDefault()
        openSidePane(name)
    }

    onDragOver = (event) => {
        const { openSidePane } = this.props

        event.preventDefault()
        openSidePane('analyze')
        const analyze_pane = document.getElementsByClassName('analyze-pane')
        analyze_pane[0]?.scrollIntoView()
    }

    handleToggle(event) {
        const { toggleSidePane } = this.props

        event.stopPropagation()
        toggleSidePane()
    }

    selectedTab() {
        const { selected, children } = this.props

        return selected || children[0].props.name
    }

    header = () => {
        const { open } = this.props

        const labels = (component) => {
            return (
                <li
                    key={component.props.name}
                    className={classNames({
                        active: this.selectedTab() === component.props.name,
                    })}
                >
                    <a
                        href="#"
                        onClick={this.handleTabClick.bind(
                            this,
                            component.props.name
                        )}
                    >
                        {component.props.label}
                    </a>
                </li>
            )
        }

        return (
            <div className="pane-header">
                <ul className="tabs-labels">
                    {this.props.children.map(labels)}
                </ul>
                <span
                    className="arrow-holder"
                    onClick={this.handleToggle.bind(this)}
                >
                    <span className={classNames('arrow', { open })} />
                </span>
            </div>
        )
    }

    content = () => {
        const { children } = this.props

        return (
            <div className="pane-content">
                {children.find((c) => c.props.name === this.selectedTab())}
            </div>
        )
    }

    handlePointerDown = (event) => {
        this.setState({ isDragging: true })
        this.resizer.setPointerCapture(event.pointerId)
        document.body.classList.add('resizing')
    }

    handlePointerMove = (event) => {
        const { isDragging } = this.state

        if (isDragging) {
            const newWidth = window.innerWidth - event.clientX
            const minWidth = paneSecondaryMinWidth
            const maxWidth = window.innerWidth - panePrimaryMinWidth
            this.setState({
                width: Math.max(Math.min(newWidth, maxWidth), minWidth),
            })
        }
    }

    handlePointerUp = (event) => {
        this.setState({ isDragging: false })
        this.resizer.releasePointerCapture(event.pointerId)
        document.body.classList.remove('resizing')
    }

    isOpen = () => {
        const { device, open } = this.props

        if (!device) {
            return true
        }
        return [Device.laptop, Device.desktop].includes(device) || open
    }

    render() {
        const { device } = this.props
        const { width } = this.state

        const paneSecondaryStyle = {}
        if (device) {
            if (width) {
                paneSecondaryStyle.width = `${width}px`
            } else {
                paneSecondaryStyle.width = '100%'
            }
        }

        return (
            <>
                <div
                    className="pane-secondary"
                    style={paneSecondaryStyle}
                    onDragOver={this.onDragOver}
                >
                    <div
                        className="pane-resizer"
                        onPointerDown={this.handlePointerDown}
                        onPointerMove={this.handlePointerMove}
                        onPointerUp={this.handlePointerUp}
                        ref={(div) => (this.resizer = div)}
                    />
                    {this.header()}
                    <CSSTransition
                        in={this.isOpen()}
                        timeout={paneTransitionDuration}
                        classNames="pane"
                        unmountOnExit
                    >
                        {this.content}
                    </CSSTransition>
                </div>
            </>
        )
    }
}
