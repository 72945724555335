export const ADD_MESSAGE = 'ADD_MESSAGE'
export const addMessage = (level, content) => ({
    type: ADD_MESSAGE,
    level,
    content,
})

export const DISMISS_MESSAGE = 'DISMISS_MESSAGE'
export const dismissMessage = (id) => ({
    type: DISMISS_MESSAGE,
    id,
})
