export function initializeGa() {
    ;(function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r
        ;(i[r] =
            i[r] ||
            function () {
                ;(i[r].q = i[r].q || []).push(arguments)
            }),
            (i[r].l = 1 * new Date())
        ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
        a.async = 1
        a.src = g
        m.parentNode.insertBefore(a, m)
    })(
        window,
        document,
        'script',
        'https://www.google-analytics.com/analytics.js',
        'ga'
    )

    ga('create', 'UA-60982524-2', 'auto')
    ga('send', 'pageview')
}

export default function sendGA() {
    if (PRODUCTION) {
        ga.apply(window, arguments)
    } else {
        console.log(arguments)
    }
}

export function sendGAConversion(send_to) {
    if (PRODUCTION) {
        gtag('event', 'conversion', {'send_to': send_to})
    } else {
        console.log(send_to)
    }
}
