import { drop, partial } from 'lodash'

import { expandTree, flattenTree } from './utils'
import { containsArray, cacheLastResult } from 'utils'
import { unabbrValueRepresentation } from 'utils/abbreviations'

export const expectedPathPrefix = '/ciods'

const _getFocusedNode = (urlPath) => {
    if (urlPath === '/ciods') {
        return []
    } else if (!urlPath.startsWith(expectedPathPrefix + '/')) {
        throw new Error('Expect urlPath to begin with "/ciods"')
    } else {
        return drop(urlPath.split('/'), 2)
    }
}

export const getFocusedNode = cacheLastResult(_getFocusedNode)

/**
 * Given the DICOM standard, and a list of open nodes, return a
 * table-representation of the nodes that are visible.
 */
export const getVisibleNodesTable = cacheLastResult((standard, openNodes) => {
    const getChildren = partial(
        getOpenChildrenFromStandard,
        standard,
        openNodes
    )
    const tree = expandTree(getChildren)

    const tablePaths = flattenTree(tree)

    const rowDetails = partial(getRowDetailsFromStandard, standard, openNodes)
    return tablePaths.map(rowDetails)
})

const getOpenChildrenFromStandard = (standard, openNodes, node) => {
    const isOpen = containsArray(openNodes, node)
    if (isOpen) {
        return standard.nodeChildren(node)
    } else {
        return []
    }
}

const getRowDetailsFromStandard = (standard, openNodes, node) => {
    const details = standard.nodeDetails(node)
    return {
        path: node,
        name: detailedNodeName(details),
        type: detailedNodeType(details),
        hasChildren: standard.hasChildren(node),
        open: containsArray(openNodes, node),
        required: nodeRequirements(details),
    }
}

const detailedNodeName = (nodeDetails) => {
    if (nodeDetails.nodeType === 'attribute') {
        return `${nodeDetails.tag} ${nodeDetails.name}`
    } else {
        return nodeDetails.name
    }
}

const nodeRequirements = (nodeDetails) => {
    if (nodeDetails.nodeType === 'attribute') {
        return nodeDetails.type
    } else if (nodeDetails.nodeType === 'module') {
        return nodeDetails.usage
    } else {
        return ''
    }
}

const detailedNodeType = (nodeDetails) => {
    if (nodeDetails.nodeType === 'ciod') {
        return 'CIOD'
    } else if (nodeDetails.nodeType === 'module') {
        return `Module - ${nodeDetails.informationEntity}`
    } else {
        const valueRepresentationLong = unabbrValueRepresentation(
            nodeDetails.valueRepresentation
        )

        // some nodes have value representations
        let valueRepresentation
        if (valueRepresentationLong === undefined) {
            return nodeDetails.valueRepresentation
        } else {
            return valueRepresentationLong
        }
    }
}
