import React from 'react'
import classNames from 'classnames'
import { drop, isEqual, find } from 'lodash'

import { Arrow, NodeName } from '.'
import { getNodePath } from 'utils/urlActions'
import { unabbrModuleUsage, unabbrAttributeType } from 'utils/abbreviations'

export default class Node extends React.Component {
    render() {
        const { node, focusedNode, onArrowClick, onNodeClick, selectNode, missingNodes, ciodId, givenElements } = this.props

        let focusTarget = null
        let isFocused = isEqual(node.path, focusedNode)
        let isMissing = false
        let isGiven = false

        if (givenElements && node.path[0] === ciodId) {
            for (let tag in givenElements) {
                const element = givenElements[tag]
                if (isEqual(element.path.slice(0, node.path.length), node.path)) {
                    isGiven = true
                    break
                } else if (isEqual(node.path.slice(2), tag.split(','))) {
                    // Different module but same tag
                    isGiven = true
                    break
                }
            }
        }

        if (missingNodes && missingNodes.length > 0) {
            if (node.path[0] === ciodId) {
                let path = node.path.slice(1)
                for (let i = 0; i < missingNodes.length; i++) {
                    let missingNode = missingNodes[i].path.split(':')
                    if (isEqual(missingNode.slice(0, path.length), path)) {
                        isMissing = true
                        break
                    }
                }
            }
        }

        let nodeClassName = classNames(
            { focused: isFocused },
            { missing: isMissing },
            { given: isGiven }
        )
        let rowId = drop(node.path, 0).join('/')

        const handleRowClick = () => {
            focusTarget.focus()
            onNodeClick()
        }

        const setFocusTarget = (domComponent) => {
            focusTarget = domComponent
            if(isFocused && focusTarget){
                focusTarget.focus()
            }
        }

        const usage = node.type.includes('Module')
            ? unabbrModuleUsage(node.required)
            : node.type !== 'CIOD'
            ? unabbrAttributeType(node.required)
            : null

        return (
            <tr
                className={nodeClassName}
                role="link"
                onClick={handleRowClick}
                onDoubleClick={() => onArrowClick(node.path)}
                key={rowId}
            >
                <td>
                    <Arrow node={node} onArrowClick={onArrowClick} />
                    <a
                        className="row-name"
                        ref={setFocusTarget}
                        href={getNodePath(node.path).pathname}
                        onFocus={() => selectNode(node.path)}
                        onClick={(e) => {
                            e.preventDefault()
                        }}
                    >
                        <NodeName name={node.name} />
                    </a>
                </td>
                <td title={usage}>{node.required}</td>
                <td className="hidden-sm-down">{node.type}</td>
            </tr>
        )
    }
}
