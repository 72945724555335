import React from 'react'

import 'stylesheets/alert.scss'

export default class Message extends React.Component {
    render() {
        const { level, content, id, buttonDismiss } = this.props

        const className = `alert alert-${level.toLowerCase()}`
        return (
            <div className={className} role="alert">
                <p>
                    {content}
                    <a
                        className="close"
                        aria-label="Close"
                        onClick={() => buttonDismiss(id)}
                    >
                        <span aria-hidden="true">&times;</span>
                    </a>
                </p>
            </div>
        )
    }
}
