import { OPEN_SIDE_PANE, CLOSE_SIDE_PANE, TOGGLE_SIDE_PANE } from './actions'

export default (state = { open: false, selected: null }, action) => {
    switch (action.type) {
        case OPEN_SIDE_PANE:
            return {
                ...state,
                open: true,
                selected: action.selected || state.selected,
            }
        case CLOSE_SIDE_PANE:
            return { ...state, open: false }
        case TOGGLE_SIDE_PANE:
            return { ...state, open: !state.open }
        default:
            return state
    }
}
