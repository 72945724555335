import React from 'react'
import '../containers/AnalyzePane.scss'
import { getNodeParent } from 'modules/tree/utils'
import { connect } from 'react-redux'

import * as actions from '../actions'
import { Change } from '../typeUtils'
import { DicomElement } from '../dataset'

interface EntryProps {
    element: DicomElement
    openFilePath: (
        node: string[],
        nodeToOpen: string[]
    ) => (event: any, first?: boolean) => void
    updatePendingChange: (change: Change) => void
    isInvalid: false
}

interface EntryState {
    inputValue: string | null
}

export class Entry extends React.Component<EntryProps, EntryState> {
    constructor(props: EntryProps) {
        super(props)

        this.state = {
            inputValue: props.element.text
        }
    }

    updateValue(e: any) {
        this.props.updatePendingChange({
            tagPath: this.props.element.uniquePath,
            value: e.target.value
        })
        this.setState({
            inputValue: e.target.value
        })
    }

    render() {
        const { openFilePath } = this.props
        const { tagName, text, path, isEmpty, vr } = this.props.element
        let { formattedText } = this.props.element

        const isSequence = vr === 'SQ'
        const isArrayBuffer = formattedText === '[object ArrayBuffer]'

        if (isArrayBuffer) {
            formattedText =
                '' +
                this.props.element.attr.Value[0].byteLength +
                ' byte buffer'
        }

        const handleClick = openFilePath(path, getNodeParent(path))

        const tagText =
            path.length > 0 ? (
                <a
                    href={'/ciods/' + path.join('/')}
                    className="tag-name"
                    onClick={handleClick}
                >
                    {tagName}
                </a>
            ) : (
                <span className="tag-text">{tagName}</span>
            )
        const dateTitle = formattedText !== text ? text : ''
        const textClass = this.props.isInvalid ? 'text input invalid' : 'text input'

        if (isSequence || isArrayBuffer) {
            const outputText = isEmpty ? 'Empty' : formattedText
            return (
                <li className={'entry'}>
                    {tagText}
                    <span title={dateTitle} className={'text'}>
                        : {outputText}
                    </span>
                </li>
            )
        }

        return (
            <li className={'entry'}>
                {tagText}:
                <input
                    placeholder="Empty"
                    onChange={this.updateValue.bind(this)}
                    title={dateTitle}
                    className={textClass}
                    value={this.state.inputValue}
                />
            </li>
        )
    }
}

const mapStateToProps = (state: any, ownProps: any) => ({})
const mapDispatchToProps = (dispatch: any) => ({
    updatePendingChange: (change: Change) =>
        dispatch(actions.updatePendingChange(change))
})

const EntryContainer = connect(mapStateToProps, mapDispatchToProps)(Entry)
export default EntryContainer
