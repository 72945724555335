import React from 'react'

import { NodeName } from '.'

export default class NodeLink extends React.Component {
    render() {
        const {
            node,
            standard,
            onNodeClick,
            includeNodeType = false,
            includeTag = false,
        } = this.props
        const details = standard.nodeDetails(node)

        let name = includeNodeType ? details.fullName : details.name
        if (details.nodeType === 'attribute') {
            name = `${details.tag} ${name}`
        }

        return (
            <a onClick={onNodeClick}>
                <NodeName name={name} includeTag={includeTag} />
            </a>
        )
    }
}
