import React from 'react'
import { extend, map } from 'lodash'

import { parentsPaths } from '../utils'
import { createMarkup } from 'utils/react'

export default class SearchEngineBreadcrumbs extends React.Component {
    render() {
        const { focusedNode, standard } = this.props
        const allPaths = parentsPaths(focusedNode)
        allPaths.push(focusedNode)
        const elements = map(allPaths, createSchemaBreadcrumb(standard))
        return <div dangerouslySetInnerHTML={createScript(elements)} />
    }
}

const createSchemaBreadcrumb = (standard) => (value, index) => {
    const details = standard.nodeDetails(value)
    return `{
        "@type": "ListItem",
        "position": ${index + 1},
        "item": {
          "@id": ${
              '"' +
              'https://dicom.innolitics.com/ciods/' +
              value.join('/') +
              '"'
          },
          "name": ${'"' + details.fullName + '"'},
          "image": ""
        }
    }`
}

// TODO: clean up this using JSON.stringify or something...
const createScript = (elements) => {
    return createMarkup(`
        <script type="application/ld+json">
            {
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [${elements}]
            }
        </script>
    `)
}
