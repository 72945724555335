import { combineReducers } from 'redux'

import externalReferences from 'modules/details/reducers'
import messages from 'modules/messages/reducers'
import panes from 'modules/panes/reducers'
import search from 'modules/search/reducers'
import analyze from 'modules/analyze/reducers'
import tree from 'modules/tree/reducers'

export default combineReducers({
    externalReferences,
    messages,
    panes,
    search,
    analyze,
    tree,
})
