import React from 'react'

import panes from 'stylesheets/panes.scss'

const tabletMinWidth = parseInt(panes.tabletMinWidth)
const laptopMinWidth = parseInt(panes.laptopMinWidth)
const desktopMinWidth = parseInt(panes.desktopMinWidth)

export class Device {
    static phone = 'phone'
    static tablet = 'tablet'
    static laptop = 'laptop'
    static desktop = 'desktop'
}

export const DeviceContext = React.createContext(Device.desktop)

export default class DeviceProvider extends React.Component {
    static getDevice(width) {
        if (width < tabletMinWidth) {
            return Device.phone
        } else if (width < laptopMinWidth) {
            return Device.tablet
        } else if (width < desktopMinWidth) {
            return Device.laptop
        } else {
            return Device.desktop
        }
    }

    constructor(props) {
        super(props)

        this.state = {
            device: ON_SERVER
                ? null
                : DeviceProvider.getDevice(window.innerWidth),
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDevice)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDevice)
    }

    updateDevice = () => {
        this.setState({ device: DeviceProvider.getDevice(window.innerWidth) })
    }

    render() {
        const { device } = this.state

        return (
            <DeviceContext.Provider value={device}>
                {this.props.children}
            </DeviceContext.Provider>
        )
    }
}
