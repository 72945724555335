import React from 'react'
import classNames from 'classnames'
import { range } from 'lodash'

import withDevice, { Device } from 'modules/core/hocs/withDevice'

import './SearchPaginator.scss'

@withDevice
export default class SearchPaginator extends React.Component {
    render() {
        const {
            device,
            page: currentPage,
            size,
            numResults,
            onSearch,
            query,
        } = this.props

        if (numResults === 0) {
            return null
        }

        const numPages = Math.ceil(numResults / size)

        let numDisplayedPages
        if (device === Device.phone) {
            numDisplayedPages =
                currentPage === 1
                    ? Math.min(numPages, 6)
                    : Math.min(numPages, 5)
        } else {
            numDisplayedPages =
                currentPage === 1
                    ? Math.min(numPages, 10)
                    : Math.min(numPages, 9)
        }

        let start = currentPage - Math.floor(numDisplayedPages / 2)
        let stop = start + numDisplayedPages

        if (numPages < numDisplayedPages) {
            start = 1
            stop = numPages + 1
        } else if (start < 1) {
            const delta = 1 - start
            start += delta
            stop += delta
        } else if (stop > numPages + 1) {
            const delta = stop - (numPages + 1)
            start -= delta
            stop -= delta
        }

        const pages = range(start, stop)
        return (
            pages.length > 1 && (
                <ul className="paginator">
                    {currentPage > 1 && (
                        <li>
                            <a
                                onClick={() =>
                                    onSearch(query, size, currentPage - 1)
                                }
                            >
                                &#9664;
                            </a>
                        </li>
                    )}
                    {pages.map((page) => (
                        <li key={page}>
                            <a
                                className={classNames({
                                    active: page === currentPage,
                                })}
                                onClick={() => onSearch(query, size, page)}
                            >
                                {page}
                            </a>
                        </li>
                    ))}
                    {currentPage < numPages && (
                        <li>
                            <a
                                onClick={() =>
                                    onSearch(query, size, currentPage + 1)
                                }
                            >
                                &#9654;
                            </a>
                        </li>
                    )}
                </ul>
            )
        )
    }
}
