import { find, partial, isEqual, take } from 'lodash'

export const containsArray = (arrayOfArrays, array) => {
    return Boolean(find(arrayOfArrays, partial(isEqual, array)))
}

export const cacheLastResult = (func) => {
    let lastArgs = null
    let lastResult = null
    return (...args) => {
        if (
            lastArgs !== null &&
            lastArgs.length === args.length &&
            args.every((value, index) => value === lastArgs[index])
        ) {
            return lastResult
        }
        lastResult = func(...args)
        lastArgs = args
        return lastResult
    }
}

export const childNodeOf = (parent, potentialChild) => {
    const childIsDeeper = potentialChild.length - parent.length > 0
    return childIsDeeper && isEqual(take(potentialChild, parent.length), parent)
}

export function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}
