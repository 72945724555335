import thunkMiddleware from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'
import { batchDispatchMiddleware } from 'redux-batched-actions'

import rootReducer from '../reducer'

export default (preloadedState) => {
    return createStore(
        rootReducer,
        preloadedState,
        applyMiddleware(batchDispatchMiddleware, thunkMiddleware)
    )
}
