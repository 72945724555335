import React from 'react'
import Helmet from 'react-helmet'

export default class Meta extends React.Component {
    render() {
        const { title, description, canonicalUrl } = this.props

        return (
            <Helmet
                title={title}
                titleTemplate="%s &ndash; DICOM Standard Browser"
                meta={[{ name: 'description', content: description }]}
                link={[{ rel: 'canonical', href: canonicalUrl }]}
            />
        )
    }
}
