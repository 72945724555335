import React from 'react'

import './ExamplesCell.scss'

export default class ExamplesCell extends React.Component {
    render() {
        return (
            <ul className="examples-cell">
                {this.props.examples.map((example, i) => (
                    <li key={i}>{example}</li>
                ))}
            </ul>
        )
    }
}
