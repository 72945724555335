import {
    unabbrModuleUsage,
    unabbrValueRepresentation,
    unabbrAttributeType,
} from 'utils/abbreviations'

import aggregatedTags from 'utils/aggregated_tags.json'

export const moduleDetails = (node) => {
    let details = [
        { header: 'Information Entity', value: node.informationEntity },
        {
            header: 'Usage',
            value: unabbrModuleUsage(node.usage, { showAbbr: true }),
        },
    ]

    if (node.conditionalStatement) {
        details.push({
            header: 'Conditional Statement',
            value: node.conditionalStatement,
        })
    }
    return details
}

export const attributeDetails = (node, fileValue) => {
    const numberOfExamples = 3

    let details = [
        { header: 'Tag', value: node.tag },
        {
            header: 'Type',
            value: unabbrAttributeType(node.type, { showAbbr: true }),
        },
        { header: 'Keyword', value: node.keyword },
        { header: 'Value Multiplicity', value: node.valueMultiplicity },
        {
            header: 'Value Representation',
            value: unabbrValueRepresentation(node.valueRepresentation, {
                showAbbr: true,
            }),
        },
    ]

    if (aggregatedTags[node.tag]) {
        const uniqueExamples = [...new Set(aggregatedTags[node.tag])]

        details.push({
            header: 'Example Values',
            value: uniqueExamples.slice(0, numberOfExamples),
        })
    }

    if (fileValue !== null) {
        if (node.valueRepresentation === 'SQ') {
            details.push({ header: 'Sequence length', value: fileValue })
        } else {
            details.push({ header: 'File Value', value: fileValue })
        }
    }
    return details
}
