import React from 'react'
import PropTypes from 'prop-types'

export default class DetailPaneSection extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        links: PropTypes.arrayOf(PropTypes.element),
        collapsible: PropTypes.bool,
    }

    static defaultProps = {
        links: [],
        collapsible: true,
    }

    state = { open: true }

    isOpen() {
        const { collapsible } = this.props
        const { open } = this.state

        return !collapsible || open
    }

    toggle = () => {
        const { open } = this.state

        this.setState({ open: !open })
    }

    render() {
        const { children, collapsible, title } = this.props

        let links = this.props.links.slice()
        if (collapsible) {
            links.push(
                <a href="#" onClick={this.toggle}>
                    {this.isOpen() ? 'Collapse' : 'Open'}
                </a>
            )
        }

        let linkList
        if (links.length > 0) {
            const linkListItems = links.map((link, index) => (
                <li key={index}>{link}</li>
            ))
            linkList = (
                <div className="detail-pane-navbar">
                    <ul className="link-list text-muted">{linkListItems}</ul>
                </div>
            )
        } else {
            linkList = null
        }

        return (
            <div>
                {linkList}
                <div className="m-a-1 detail-pane-section">
                    <h2 className="section-title text-secondary">{title}</h2>
                    {this.isOpen() ? children : null}
                </div>
            </div>
        )
    }
}
