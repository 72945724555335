import { Change } from './typeUtils'
import { getAvailableExamples, downloadExampleDICOM } from './publicExamplesApi'
import { openSidePane } from '../panes/actions'
import { openNode } from '../tree/actions'

export const UPLOAD_FILE = 'UPLOAD_FILE'
export const uploadFile = (dataset) => ({
    type: UPLOAD_FILE,
    dataset,
})

export const RESET_ALL = 'RESET_ALL'
export const resetAll = () => ({
    type: RESET_ALL,
})

export const UPDATE_PENDING_CHANGE = 'UPDATE_PENDING_CHANGE'
export const updatePendingChange = (change: Change) => ({
    type: UPDATE_PENDING_CHANGE,
    tagPath: change.tagPath,
    value: change.value,
})

export const COMMIT_CHANGES = 'COMMIT_CHANGES'
export const commitChanges = () => ({
    type: COMMIT_CHANGES,
})

export const CLEAR_CHANGES = 'CLEAR_CHANGES'
export const clearChanges = () => ({
    type: CLEAR_CHANGES,
})

export const CREATE_DOWNLOAD = 'CREATE_DOWNLOAD'
export const createDownload = () => ({
    type: CREATE_DOWNLOAD,
})

export const downloadModifiedFile = () => {
    return (dispatch: any, getState: any) => {
        dispatch(commitChanges());
        dispatch(createDownload());
        const state = getState();
        state.analyze.download.blob && window.open(state.analyze.download.uri, '_blank');
    }
}

export const QUEUE_EXAMPLE = 'QUEUE_EXAMPLE'
export const queueExample = (example: string) => ({
    type: QUEUE_EXAMPLE,
    payload: example,
})


export const EXAMPLE_DOWNLOAD_STARTED = 'EXAMPLE_DOWNLOAD_STARTED'
export const exampleDownloadStarted = () => ({
    type: EXAMPLE_DOWNLOAD_STARTED,
})

export const EXAMPLE_DOWNLOAD_FINISHED = 'EXAMPLE_DOWNLOAD_FINISHED'
export const exampleDownloadFinished = () => ({
    type: EXAMPLE_DOWNLOAD_FINISHED,
})

export const EXAMPLE_DOWNLOAD_FAILED = 'EXAMPLE_DOWNLOAD_FAILED'
export const exampleDownloadFailed = () => ({
    type: EXAMPLE_DOWNLOAD_FAILED,
})


export const DOWNLOAD_EXAMPLE = 'DOWNLOAD_EXAMPLE'
export const downloadExample = (example: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(exampleDownloadStarted())
        try {
            await loadExampleDicom(example, dispatch)
        } catch {
            dispatch(exampleDownloadFailed())
        }
        dispatch(exampleDownloadFinished())
    }
}

async function loadExampleDicom(exampleDicomName: string, dispatch: any) {
    const existingExamples = await getAvailableExamples();
    if (existingExamples.includes(exampleDicomName)) {
        const dataset = await downloadExampleDICOM(exampleDicomName);
        dispatch(uploadFile(dataset));
        dispatch(openSidePane('analyze'));
        dispatch(openNode([dataset.ciodId]));
    }
}
