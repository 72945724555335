import standard from '../../utils/dicomStandard'
import { data } from 'dcmjs'
import * as _ from 'lodash'
import { map, filter, find } from 'lodash'

import { EncodingConverter, createEncConverter } from './encConverter'
import {
    CiodModuleRelationships,
    Dict,
    ModuleToAttributeRelationship,
    ModuleToAttributeRelationships,
    PendingChanges,
} from './typeUtils'

type VR =
    | 'AE'
    | 'AS'
    | 'AT'
    | 'CS'
    | 'DA'
    | 'DS'
    | 'DT'
    | 'FL'
    | 'FD'
    | 'IS'
    | 'LO'
    | 'LT'
    | 'OB'
    | 'OD'
    | 'OF'
    | 'OL'
    | 'OV'
    | 'OW'
    | 'PN'
    | 'SH'
    | 'SL'
    | 'SQ'
    | 'SS'
    | 'ST'
    | 'SV'
    | 'UC'
    | 'UI'
    | 'UL'
    | 'UN'
    | 'UR'
    | 'US'
    | 'UT'
    | 'UV'

interface DicomAttribute {
    vr: VR
    Value: Array<string | number | Dict<DicomAttribute>>
}
interface DicomSequence extends DicomAttribute {
    vr: 'SQ'
    Value: Array<Dict<DicomAttribute>>
}

interface RawDataset {
    meta: Dict<DicomAttribute>
    dict: Dict<DicomAttribute>
}

const getCharacterSet = (dataset: RawDataset): string => {
    return dataset.meta['00080005']
        ? (dataset.meta['00080005'].Value[0] as string)
        : 'IR 100'
}

export class Dataset {
    data: RawDataset;
    imageType: string;
    ciodId: string;
    charSet: string;
    paths: Set<string>;
    elements: Dict<DicomElement>;
    uniqueElements: Dict<DicomElement>;
    fileName: string;
    moduleAttributes: ModuleToAttributeRelationships;
    missing: ModuleToAttributeRelationships;
    invalid: DicomElement[];

    constructor (rawDataset: RawDataset, fileName: string, imageTypeOverride?: string) {
        this.data = rawDataset;
        this.imageType = _.isUndefined(imageTypeOverride) ? this.origImageType() : imageTypeOverride;
        this.ciodId = this.getCiodId(this.imageType);

        this.charSet = getCharacterSet(rawDataset);

        const ciodModules = filter(standard.ciodToModule, (rel) => (rel.ciodId === this.ciodId));
        this.moduleAttributes = this.getModuleAttributes(ciodModules);

        this.paths = new Set();
        this.elements = {};
        this.uniqueElements = {};

        this.extractData(this.data.dict, this.moduleAttributes);
        this.extractData(this.data.meta, this.moduleAttributes);
        this.missing = this.findRequired(ciodModules, this.moduleAttributes);
        this.fileName = fileName;
        this.invalid = []
    }

    origImageType = (): string => {
        return standard.sops[this.data.meta['00020002']['Value'][0]].ciod
    }

    // Format into id of ciods.json file
    getCiodId = (imageType: string): string => {
        const replacements = {
            'computed tomography': 'ct',
            'magnetic resonance': 'mr',
            radiotherapy: 'rt',
            electrocardiogram: 'ecg',
            oct: 'optical coherence tomography',
            'enhanced x-ray rf image': 'enhanced xrf image',
            'x-ray rf': 'xrf',
        }

        if (imageType) {
            let id = imageType
            id = id.toLowerCase()

            for (let str in replacements) {
                if (id.indexOf(str) !== -1) {
                    id = id.replace(str, replacements[str])
                    break
                }
            }
            id = id.trim()
            return id.replace(/[ \/]/g, '-')
        }
    }

    getModuleAttributes = (
        ciodModules: CiodModuleRelationships
    ): ModuleToAttributeRelationships => {
        const reachableModules = new Set(map(ciodModules, 'moduleId'))
        return filter(standard.moduleToAttribute, (rel) =>
            reachableModules.has(rel.moduleId)
        )
    }

    findRequired = (
        ciodModules: CiodModuleRelationships,
        moduleAttributes: ModuleToAttributeRelationships
    ): ModuleToAttributeRelationships => {
        let requiredModules = filter(ciodModules, (rel) => rel.usage === 'M')
        let requiredModuleIds = new Set(map(requiredModules, 'moduleId'))
        let requiredPaths: Set<string> = new Set()
        const requiredAttributes = filter(moduleAttributes, (rel) =>
            this.isRequired(rel, requiredModuleIds, requiredPaths)
        )

        return requiredAttributes
    }

    isRequired = (
        rel: ModuleToAttributeRelationship,
        requiredModuleIds: Set<string>,
        requiredPaths: Set<string>
    ): boolean => {
        const path = rel.path.split(':').slice(1)
        const subPath = path.slice(0, -1).join()
        const isTypeOne = rel.type === '1'
        const isInPaths = this.paths.has(path.join())
        const isRequiredModule =
            requiredModuleIds.has(rel.moduleId) && path.length === 1
        const isRequired =
            isTypeOne &&
            !isInPaths &&
            (isRequiredModule ||
                this.paths.has(subPath) ||
                requiredPaths.has(subPath))

        if (isRequired) {
            requiredPaths.add(path.join())
        }

        return isRequired
    }

    extractData = (
        dataset: Dict<DicomAttribute>,
        moduleAttributes: ModuleToAttributeRelationships,
        currPath: string[] = [],
        uniquePath: string[] = [],
        depth: number = 0,
        itemIndex: number = -1
    ) => {
        Object.keys(dataset).forEach((tag: string, index: number) => {
            const newPath = currPath.concat([tag.toLowerCase()])
            const newUniquePath = uniquePath.concat([tag.toLowerCase()])
            // If this is the first element of a dataset inside a sequence, mark the sequence index
            const sequenceIndex = index === 0 ? itemIndex : -1
            const element = new DicomElement(
                tag,
                dataset[tag],
                this.ciodId,
                newPath,
                newUniquePath,
                sequenceIndex,
                moduleAttributes,
                this.charSet
            )

            this.elements[newUniquePath.join()] = element
            this.uniqueElements[newPath.join()] = element

            if (element.tag.toLowerCase() === '7fe00010') {
                console.warn('element: ', element)
            }

            if (
                element.text !== '' &&
                (element.vr !== 'SQ' ||
                    (element.vr === 'SQ' && element.attr.Value.length > 0))
            ) {
                this.paths.add(newPath.join())
            }

            if (element.vr == 'SQ') {
                console.warn('Extracting sequence!')
                console.warn(element.attr.Value)
                let attr = element.attr as DicomSequence
                for (
                    let sequenceIndex = 0;
                    sequenceIndex < attr.Value.length;
                    sequenceIndex++
                ) {
                    const sequenceElement = attr.Value[sequenceIndex]
                    this.extractData(
                        sequenceElement,
                        moduleAttributes,
                        newPath,
                        newUniquePath.concat([sequenceIndex.toString()]),
                        depth + 1,
                        sequenceIndex
                    )
                }
            }
        })
    }
}

export class DicomElement {
    attributeName: string
    tag: string
    tagName: string
    attr: DicomAttribute
    vr: string
    text: string
    isEmpty: boolean
    formattedText: string
    ciodId: string
    currPath: string[]
    path: string[]
    depth: number
    uniquePath: string[]
    index: number
    charSet: string

    constructor(
        tag: string,
        attr: DicomAttribute,
        ciodId: string,
        currPath: string[],
        uniquePath: string[],
        index: number,
        moduleAttributes: ModuleToAttributeRelationships,
        charSet: string
    ) {
        this.tag = tag
        this.attr = attr
        this.tagName = this.getTagName(tag)
        this.vr = attr.vr
        this.text = this.getText(attr)
        this.isEmpty = this.text === '' || this.text === undefined
        this.formattedText = this.formatText()
        this.ciodId = ciodId
        this.currPath = currPath
        this.path = this.getPath(currPath, moduleAttributes)
        this.depth = currPath.length - 1
        this.uniquePath = uniquePath
        this.index = index
        this.charSet = charSet
    }

    getPath = (
        currPath: string[],
        moduleAttributes: ModuleToAttributeRelationships
    ): string[] => {
        const foundTag = find(
            moduleAttributes,
            (rel) => rel.path === rel.moduleId + ':' + currPath.join(':')
        )
        return foundTag ? [this.ciodId, foundTag.moduleId, ...currPath] : []
    }

    getVr = (tag: string): string | null => {
        const attr = standard.attributes[tag.toLowerCase()]
        return attr ? attr.valueRepresentation : null
    }

    getTagName = (tag: string): string | null => {
        if (['fffee000', 'fffee00d', 'fffee00d'].includes(tag)) {
            return null
        }
        const attr = standard.attributes[tag.toLowerCase()]
        if (tag.toLowerCase() === '7fe00010') {
            console.warn('Pixel data found: ', tag, attr)
        }
        return attr
            ? this.vr === 'SQ'
                ? `${attr.name} Length`
                : attr.name
            : null
    }

    isStringVr = (vr: string): boolean => {
        return (
            [
                'AT',
                'FL',
                'FD',
                'OB',
                'OF',
                'OW',
                'SL',
                'SQ',
                'SS',
                'UL',
                'US',
            ].indexOf(vr) === -1
        )
    }

    isCharDependentVr = (vr: string): boolean => {
        return ['LO', 'LT', 'PN', 'SH', 'UT', 'ST'].indexOf(vr) !== -1
    }

    isASCII = (str: string): boolean => {
        return /^[\x00-\x7F]*$/.test(str)
    }

    getEncConverter = (): EncodingConverter => {
        const defaultEncConverter = (buf: Buffer) => buf.toString('latin1')
        const converter = createEncConverter(this.charSet)
        return converter ? converter : defaultEncConverter
    }

    getText = (attr: DicomAttribute): string => {
        let text = ''
        if (attr.vr === 'SQ') {
            text = attr.Value.length.toString()
        } else if (attr.Value.length > 128) {
            text = `Data of length ${attr.Value.length} for VR ${this.vr} too long to show`
        } else {
            text = attr.Value.toString()
        }

        return text
    }

    formatText = (): string => {
        if (this.isEmpty) {
            return 'Empty'
        }

        let text = this.text
        if (this.tagName && this.vr === 'DA' && text) {
            const dates = text.split('\\')
            let dateString = ''
            dates.forEach(
                (date) => (dateString += this.formatDate(date) + '\\')
            )
            text = dateString.substring(0, dateString.length - 1)
        }
        return text
    }

    formatDate = (text: string): string => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]

        const year = text.substring(0, 4)
        const month = months[parseInt(text.substring(4, 6)) - 1]
        const day = text.substring(6)
        return day && month && year ? `${month} ${day}, ${year}` : text
    }
}

export const applyChanges = (dataset: Dataset, changes: PendingChanges) => {
    for (let tagPath in changes) {
        const newValue = changes[tagPath]
        const tagSequence = tagPath.split('_')
        let attr = dataset.data.dict[tagSequence[0]]
        for (const tag of tagSequence.slice(1)) {
            const isSequenceIndex = !tag.match(/[0-9]{8}/)
            if (isSequenceIndex) {
                attr = attr.Value[Number(tag)]
            } else {
                attr = attr[tag]
            }
        }
        attr.Value = newValue
    }
    return dataset
}
