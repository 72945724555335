import url from 'url'
import { uniqueId } from 'lodash'
import sendGA from 'external/googleAnalytics'

export const search = (query, size, page = 1) => {
    return async (dispatch, getState) => {
        const token = uniqueId()
        dispatch(searchBegin(query, size, page, token))

        if (!query) {
            dispatch(searchFinish())
            sendGA('send', 'event', 'search', 'finish')
            return
        }

        try {
            const promise = fetch(
                url.resolve(process.env.ELASTICSEARCH_URL, '/_search/'),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        from: (page - 1) * size,
                        size,
                        query: {
                            match: {
                                _all: query,
                            },
                        },
                    }),
                }
            )

            const [results] = await Promise.all([
                Promise.race([
                    promise,
                    new Promise((_, reject) =>
                        setTimeout(() => reject(new Error('Timeout')), 10000)
                    ),
                ]),
                new Promise((resolve) => setTimeout(() => resolve(), 250)),
            ])

            const currentToken = getState().search.token
            if (currentToken === token) {
                dispatch(searchFinish(await results.json()))
            }
        } catch (error) {
            const currentToken = getState().search.token
            if (currentToken === token) {
                dispatch(
                    searchError(
                        'Your search could not be completed. Please try again later.'
                    )
                )
            }
        }
    }
}

export const SEARCH_BEGIN = 'SEARCH_BEGIN'
export const searchBegin = (query, size, page, token) => ({
    type: SEARCH_BEGIN,
    query,
    size,
    page,
    token,
})

export const SEARCH_FINISH = 'SEARCH_FINISH'
export const searchFinish = (results) => ({
    type: SEARCH_FINISH,
    results,
})

export const SEARCH_ERROR = 'SEARCH_ERROR'
export const searchError = (error) => ({
    type: SEARCH_ERROR,
    error,
})
