import React from 'react'

import { DeviceContext } from '../containers/DeviceProvider'
import { getDisplayName } from 'utils'

export { Device } from '../containers/DeviceProvider'

export default function withDevice(WrappedComponent) {
    return class extends React.Component {
        static contextType = DeviceContext
        static displayName = `WithDevice(${getDisplayName(WrappedComponent)})`

        render() {
            return <WrappedComponent device={this.context} {...this.props} />
        }
    }
}
