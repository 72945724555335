import React from 'react'
import { Route } from 'react-router-dom'

export default class Status extends React.Component {
    render() {
        return (
            <Route
                render={({ staticContext }) => {
                    if (staticContext) {
                        staticContext.status = this.props.code
                    }
                    return this.props.children
                }}
            />
        )
    }
}
