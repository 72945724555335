import React from 'react'

import { ReferencedSection } from '../containers'
import { DetailPaneSection, NodeDescription, NodeDetailsTable } from '.'

import 'stylesheets/linkList.scss'
import 'stylesheets/spacing.scss'
import './DetailPane.scss'

export default class DetailPane extends React.Component {
    render() {
        const { standard, focusedNode, dataset } = this.props
        let sections
        if (focusedNode.length !== 0) {
            let nodeDetails = standard.nodeDetails(focusedNode)

            const topSection = nodeDetailsSection(nodeDetails, dataset)
            const referenceSections = nodeDetails.externalReferences.map(
                externalReferenceSection
            )
            sections = [topSection].concat(referenceSections)
        } else {
            sections = [welcomeSection()]
        }
        return <div className="detail-pane">{sections}</div>
    }
}

const nodeDetailsSection = (node, dataset) => {
    const topSectionLinks = [
        <a
            href="//github.com/innolitics/dicom-standard/issues/new?labels=browser%2C+bug&template=bug-report.md"
            target="_blank"
        >
            Report Error
        </a>,
        <a href={node.linkToStandard} target="_blank">
            View in Standard
        </a>,
    ]

    let fileValue = null
    let isMissing = false
    let isEmpty = true
    if (dataset) {
        if (node.nodeType === 'attribute') {
            let path = node.path.split(':').splice(1)
            let element = dataset.uniqueElements[path]

            for (let i = 0; i < dataset.missing.length; i++) {
                if (node.path === dataset.missing[i].path) {
                    isMissing = true
                    break
                }
            }

            if (isMissing) {
                fileValue = 'Missing'
            } else if (element) {
                isEmpty = element.isEmpty
                fileValue = element.formattedText
                if (
                    element.text !== 'binary data' &&
                    element.formattedText === 'binary data'
                ) {
                    isEmpty = true
                }
            }
        }
    }

    return (
        <DetailPaneSection
            title={node.fullName}
            links={topSectionLinks}
            collapsible={false}
            key="node-details"
        >
            <NodeDetailsTable
                node={node}
                fileValue={fileValue}
                isMissing={isMissing}
                isEmpty={isEmpty}
            />
            <NodeDescription node={node} />
        </DetailPaneSection>
    )
}

const externalReferenceSection = (reference) => {
    const sectionLinks = [
        <a
            href="//github.com/innolitics/dicom-standard/issues/new?labels=browser%2C+bug&template=bug-report.md"
            target="_blank"
        >
            Report Error
        </a>,
        <a href={reference.sourceUrl} target="_blank">
            View in Standard
        </a>,
    ]

    return (
        <DetailPaneSection
            title={reference.title}
            links={sectionLinks}
            key={reference.sourceUrl}
        >
            <ReferencedSection url={reference.sourceUrl} />
        </DetailPaneSection>
    )
}

const welcomeSection = () => (
    <DetailPaneSection
        title={'Welcome'}
        links={[]}
        collapsible={false}
        key={'welcome'}
    >
        <p>
            The purpose of this application is to make the DICOM standard easier
            to navigate for software developers, researchers, and radiologists.
        </p>
        <p>
            The main pane displays Part 3 of the DICOM standard as a tree. The
            top nodes are <strong>CIODs</strong>, their children are{' '}
            <strong>modules</strong>, and their children are{' '}
            <strong>attributes</strong>. Sequence attributes may have other
            attributes as their children, and so on. Click a node to view
            details about it, including a link back to its description in the
            DICOM standard.
        </p>
        <p>
            If you find an error or want to suggest an improvement, please&nbsp;
            <a
                href="//github.com/innolitics/dicom-standard/issues/new/choose"
                target="_blank"
            >
                open an issue
            </a>
            . Feel free to{' '}
            <a href="https://innolitics.com/about/contact/">contact us</a> if
            this tool is useful to you or if you have questions or comments.
        </p>
        <p>
            This application was built by{' '}
            <a href="https://innolitics.com" target="_blank">
                Innolitics
            </a>
            , a team of medical imaging software developers.{' '}
            <strong>
                We can help you get new technology on the market faster.
            </strong>{' '}
            We are experienced with DICOM, ISO62304, image processing, machine
            learning, and web and desktop user interface development. Check out
            our <a href="https://innolitics.com/portfolio/">portfolio</a> to see
            some of our prior work.
        </p>
    </DetailPaneSection>
)
